import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, forkJoin, from, of } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

import { v4 as uuidv4 } from 'uuid';

import { ConfigurationService } from '@configuration/service/configuration.service';

import { ApiService } from './api.service';
import { PhxConstants, UserContext } from '../model';
import { AuthService } from './auth.service';
import { DocumentService } from './document.service';
import { UserBehavior } from '../model/user-behavior';
import { GoogleAnalyticsService } from './google-analytics/google-analytics.service';

@Injectable()
export class UserBehaviorService implements OnDestroy {
    onDestroy$ = new Subject<void>();

    /** NOTE: list of current behaviors (reading, writing or idle) */
    private currentActivityBehaviorsSubject = new Subject<UserBehavior[]>();
    public currentActivityBehaviors$ = this.currentActivityBehaviorsSubject.asObservable();

    /** NOTE: list of entity changed behaviors */
    private entityChangedSubject = new Subject<UserBehavior[]>();
    private entityChanged$ = this.entityChangedSubject.asObservable();

    private currentUserContext: UserContext;
    private profilePictureUrl: string;

    private currentBehavior: UserBehavior;
    private sessionId = uuidv4();

    constructor(
        private apiService: ApiService,
        private authService: AuthService,
        private configurationService: ConfigurationService,
        private documentService: DocumentService,
        private googleAnalyticsService: GoogleAnalyticsService
    ) {
        this.initSignalR();
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }

    public getCurrentBehaviors$(): Observable<UserBehavior[]> {
        /** NOTE: filter out the current user from the list of behaviors */
        return this.currentActivityBehaviors$.pipe(switchMap(currentBehaviors => of(currentBehaviors.filter(f => f.UserId !== this.currentUserContext?.User.Id))));
    }

    public trackBehavior(behaviorCode: string, pageTypeId: number, primaryId: number, secondaryId: number = 0) {
        if (this.configurationService.isFeatureActive(PhxConstants.FeatureFlags.ShowRealTimeUserBehaviors)) {

            this.authService.getCurrentProfile().pipe(
                filter(currentProfile => !!currentProfile),
                switchMap(currentProfile => forkJoin([
                    this.profilePictureUrl ? of(this.profilePictureUrl) : from(this.documentService.getProfilePicture(currentProfile.Id)),
                    this.currentUserContext ? of(this.currentUserContext) : from(this.authService.getUserContext())
                ])),
                take(1)
            ).subscribe(([profilePicture, currentUserContext]) => {
                this.currentUserContext = currentUserContext;
                this.profilePictureUrl = profilePicture;

                const request: UserBehavior = {
                    SessionId: this.sessionId,
                    BehaviorCode: behaviorCode,
                    Name: `${this.currentUserContext.User.PreferredFirstName} ${this.currentUserContext.User.PreferredLastName}`,
                    UserId: this.currentUserContext.User.Id,
                    PrimaryId: primaryId,
                    SecondaryId: secondaryId,
                    PageTypeId: pageTypeId,
                    ProfilePicture: this.profilePictureUrl?.split('?')[0]
                };

                if (behaviorCode === PhxConstants.UserBehaviorCode.UpdateEntity || this.isNewBehavior(request)) {
                    this.apiService.command('UserBehavior', request, false);
                    this.currentBehavior = request;
                }
            });
        }
    }

    /** NOTE: only emits a value when the entity has changed */
    public entityHasChanged$(pageTypeId: number, primaryId: number, secondaryId: number): Observable<boolean> {
        return this.entityChanged$.pipe(
            map(entityChanges => entityChanges.some(change => change.PageTypeId === +pageTypeId &&
                change.PrimaryId === +primaryId &&
                change.SecondaryId === +secondaryId &&
                change.UserId !== this.currentUserContext.User.Id)
            ),
            filter(hasChanged => hasChanged)
        );
    }

    public setToInactive() {
        this.apiService.command('UserBehavior', {
            ...this.currentBehavior,
            BehaviorCode: PhxConstants.UserBehaviorCode.Inactive
        }, false);
    }

    public setToActive() {
        this.apiService.command('UserBehavior', this.currentBehavior, false);
    }

    public clearUserSessionBehaviors() {
        return this.apiService.command('UserBehavior', { BehaviorCode: PhxConstants.UserBehaviorCode.Disconnect, SessionId: this.sessionId }, false);
    }

    addUserBehaviorGAEvent(selectedBehavior: UserBehavior) {
        this.googleAnalyticsService.sendClickData({
            feature: 'Real time user behavior',
            type: PhxConstants.EntityType[selectedBehavior.PageTypeId],
            action: `hover on ${selectedBehavior.BehaviorCode} user`,
        });
    }

    private isNewBehavior(behavior: UserBehavior) {
        if (!this.currentBehavior) {
            return true;
        }

        return behavior.BehaviorCode !== this.currentBehavior.BehaviorCode ||
            behavior.PageTypeId !== this.currentBehavior.PageTypeId ||
            behavior.PrimaryId !== this.currentBehavior.PrimaryId ||
            behavior.SecondaryId !== this.currentBehavior.SecondaryId;
    }

    private initSignalR() {
        this.apiService.onPublic('UserBehavior', (event, data) => {
            try {
                const newBehaviors: UserBehavior[] = JSON.parse(data.Message);

                this.currentActivityBehaviorsSubject.next(newBehaviors.filter(f => f.BehaviorCode !== PhxConstants.UserBehaviorCode.UpdateEntity));

                this.entityChangedSubject.next(newBehaviors.filter(f => f.BehaviorCode === PhxConstants.UserBehaviorCode.UpdateEntity));

            } catch (error) {
                this.currentActivityBehaviorsSubject.next([]);
                this.entityChangedSubject.next([]);

                console.error('User Behavior Service', error);
            }
        });
    }
}
