import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { cloneDeep } from 'lodash';

import { CoreDetailFormService } from './core-detail-form.service';
import { IFormService, PhxConstants } from '../../../common/model';
import { FormArray, FormBuilder, FormGroup } from '../../../common/ngx-strongly-typed-forms/model';
import {
  IPaymentContact,
  IPaymentInfo,
  IPaymentInvoice,
  IPaymentPartiesRateDetail,
  IPaymentPartyInfoes,
  IPaymentPaySideDeduction,
  IPaymentRate,
  IPaymentRatesDetail,
  IWorkOrder, OvertimeDetails
} from '../../models';
import { PtFieldViewCustomValidator } from '../../ptFieldCustomValidator';
import { WorkorderService } from '../workorder.service';

@Injectable()
export class PartyPaymentInfoFormService implements IFormService {

  formGroup: FormGroup<IPaymentPartyInfoes>;
  private isRootComponentDestroyed$: Subject<boolean>;

  private organizationIdSupplierSubscription$: Subscription;

  private organizationIdSupplierChange = new Subject();
  private isEligibleProfileForAutocalculateOvertime = false;

  constructor(
    private fb: FormBuilder,
    private coreDetailFormService: CoreDetailFormService,
    private workorderService: WorkorderService
  ) {
  }

  get paymentPartiesRateDetailFormArray(): FormArray<IPaymentPartiesRateDetail> {
    return this.formGroup.get('PaymentPartiesRateDetails') as FormArray<IPaymentPartiesRateDetail>;
  }

  get organizationIdSupplierChange$() {
    return this.organizationIdSupplierChange.asObservable();
  }

  get isOvertimeEligibleProfile(): boolean {
    return this.isEligibleProfileForAutocalculateOvertime;
  }

  isOvertimeEligibleRates(paymentRates: IPaymentRate[]): boolean {
    return paymentRates?.some(rate => rate.RateTypeId === PhxConstants.RateType.Primary && rate.RateUnitId === PhxConstants.RateUnit.Hour);
  }

  createForm(workorder: IWorkOrder, isDestroyed$: Subject<boolean>) {
    this.isRootComponentDestroyed$ = isDestroyed$;
    const paymentPartiesDetails: Array<IPaymentPartiesRateDetail> = this.mapWorkOrderToFormData(workorder);

    this.formGroup = this.fb.group<IPaymentPartyInfoes>({
      PaymentPartiesRateDetails: this.createPaymentPartiesRateDetailFormArray(paymentPartiesDetails),
      OverTimeDetails: this.createOverTimeDetailsFormGroup(workorder)
    });

    return this.formGroup;
  }

  private createOverTimeDetailsFormGroup(workorder: IWorkOrder): FormGroup<OvertimeDetails>{
    /** NOTE: 'overtime exempt' is required if worker is temp or W2 */
    this.isEligibleProfileForAutocalculateOvertime =
      (workorder.workerProfileTypeId === PhxConstants.UserProfileType.WorkerUnitedStatesW2 || workorder.workerProfileTypeId === PhxConstants.UserProfileType.WorkerTemp);

    /** NOTE: if 'overtime exempt' and 'overtime exempt reason' is not relevant then disable controls to disable validation */
    const disableIsExempt = workorder.readOnlyStorage.isTemplate || !this.isEligibleProfileForAutocalculateOvertime;
    const disableIsExemptReason = disableIsExempt || workorder.WorkOrderVersion.IsOvertimeExempt !== true;

    const acceptableTimesheetMethodologies = [PhxConstants.TimeSheetMethodology.OnlineApproval, PhxConstants.TimeSheetMethodology.OfflineApproval];
    const timesheetMethodology = workorder.WorkOrderVersion.TimeSheetMethodologyId;
    const isUsingProjects = workorder.WorkOrderVersion.IsTimeSheetUsesProjects;

    const disableAutoCalculate = workorder.readOnlyStorage.isTemplate
      || !this.workorderService.autoCalculateOvertimeIsActiveForWorkorder
      || ((timesheetMethodology && !acceptableTimesheetMethodologies.includes(timesheetMethodology)) || isUsingProjects);

    // A template and non-editable work order should display the provided value.
    // An editable work order displays a saved value or defaults to false
    const IsOvertimeExemptValue = (workorder.readOnlyStorage.IsEditable && !workorder.readOnlyStorage.isTemplate)
      ? (workorder.WorkOrderVersion.IsOvertimeExempt ?? false) :  workorder.WorkOrderVersion.IsOvertimeExempt;

    let autoCalculateOvertimeValue: boolean;
    // When viewing WO template, these settings should be blank, as per BUG #47235
    if (workorder.readOnlyStorage.isTemplate) {
      autoCalculateOvertimeValue = null;
    } else if (disableAutoCalculate) {
      autoCalculateOvertimeValue = false;
    } else {
      autoCalculateOvertimeValue = workorder.WorkOrderVersion.AutoCalculateOvertime;

      if(workorder.readOnlyStorage.IsEditable){
        // An editable work order displays a saved value or defaults to true if the profile is eligible and there is overtile eligible rate
        // Otherwise there is a null
        const initialDefaultValue =
          (this.workorderService.autoCalculateOvertimeIsActiveForWorkorder &&
            this.isEligibleProfileForAutocalculateOvertime &&
            this.isOvertimeEligibleRates(workorder.WorkOrderVersion.PaymentInfoes[0].PaymentRates)) ||
          null;
        autoCalculateOvertimeValue ??= initialDefaultValue;
      }
    }

    const disableAutoCalculateExemptionReason = disableAutoCalculate || autoCalculateOvertimeValue || !this.isEligibleProfileForAutocalculateOvertime;

    return this.fb.group<OvertimeDetails>({
      AutoCalculateOvertime: [{ value: autoCalculateOvertimeValue, disabled: disableAutoCalculate }],
      AutoCalculateOvertimeExemptionReason: [
        { value: workorder.WorkOrderVersion.AutoCalculateOvertimeExemptionReason, disabled: disableAutoCalculateExemptionReason},
        Validators.required
      ],
      IsOvertimeExempt: [{ value: IsOvertimeExemptValue, disabled: disableIsExempt }, Validators.required],
      OvertimeExemptionReason: [{ value: workorder.WorkOrderVersion.OvertimeExemptionReason, disabled: disableIsExemptReason }, Validators.required],
    });
  }

  destroyForm() {
    this.formGroup = null;
  }

  setupFormListeners() {
    this.isRootComponentDestroyed$.subscribe(() => {
      this.destroyForm();
    });

    this.setupOrganizationIdSupplierListeners();
    this.setupOvertimeFormExemptionListeners();
  }

  formGroupToPartial(workOrder: IWorkOrder): IWorkOrder {

    workOrder.WorkOrderVersion.PaymentInfoes = this.getPaymentInfoes(
      workOrder,
      this.paymentPartiesRateDetailFormArray.value
    );

    workOrder.WorkOrderVersion.PaymentInfoes.forEach((paymentInfo, index) => {
      if (index > 0) {
        const paymentRates = cloneDeep(paymentInfo.PaymentRates);
        paymentRates.forEach((rate) => {
          const ind = paymentInfo.PaymentRates.findIndex(a => a.RateTypeId === rate.RateTypeId);
          if (ind !== -1) {
            const paymentRate = paymentInfo.PaymentRates.find(a => a.RateTypeId === rate.RateTypeId);
            paymentRates[ind].Rate = paymentRate.Rate;
            paymentRates[ind].IsApplyStatHoliday = Boolean(paymentRate.IsApplyStatHoliday);
            paymentRates[ind].IsApplyDeductions = Boolean(paymentRate.IsApplyDeductions);
            paymentRates[ind].IsApplyVacation = Boolean(paymentRate.IsApplyVacation);
          }
        });
        paymentInfo.PaymentRates = paymentRates;
      }
    });

    workOrder.WorkOrderVersion.AutoCalculateOvertime = this.formGroup.getRawValue().OverTimeDetails?.AutoCalculateOvertime;
    workOrder.WorkOrderVersion.AutoCalculateOvertimeExemptionReason = this.formGroup.getRawValue().OverTimeDetails?.AutoCalculateOvertimeExemptionReason;
    workOrder.WorkOrderVersion.IsOvertimeExempt = this.formGroup.getRawValue().OverTimeDetails?.IsOvertimeExempt;
    workOrder.WorkOrderVersion.OvertimeExemptionReason = this.formGroup.getRawValue().OverTimeDetails?.OvertimeExemptionReason;

    return workOrder;
  }

  updateForm(workorder: IWorkOrder): void {
    const paymentPartiesRateDetails: Array<IPaymentPartiesRateDetail> = this.mapWorkOrderToFormData(workorder);
    const OverTimeDetails = this.createOverTimeDetailsFormGroup(workorder).getRawValue();
    this.formGroup.patchValue({
      PaymentPartiesRateDetails: paymentPartiesRateDetails,
      OverTimeDetails
    }, { emitEvent: false });

    this.updatePaymentPartiesRateDetailFormArray(this.paymentPartiesRateDetailFormArray, paymentPartiesRateDetails);

    this.setupOrganizationIdSupplierListeners();

  }

  addPaymentPartiesRateDetailFormGroup() {
    const formGroup = this.createBlankPaymentPartiesRateDetailFormGroup();
    this.paymentPartiesRateDetailFormArray.push(formGroup);
    if (!this.organizationIdSupplierSubscription$) {
      this.organizationIdSupplierSubscription$ = new Subscription();
    }
    this.organizationIdSupplierSubscription$.add(this.getOrganizationIdSupplierListener(formGroup));
  }

  deletePaymentPartiesRateDetailFormGroup(index: number) {
    this.paymentPartiesRateDetailFormArray.removeAt(index);
    this.setupOrganizationIdSupplierListeners();
    this.organizationIdSupplierChange.next(null);
  }

  paymentRateFormArray(): Array<FormArray<IPaymentRate>> {
    const formArray: Array<FormArray<IPaymentRate>> = [];
    const paymentPartiesFormArray = this.paymentPartiesRateDetailFormArray;
    if (paymentPartiesFormArray.length) {
      for (let i = 0; i < paymentPartiesFormArray.length; i++) {
        const paymentPartiesRateDetail = paymentPartiesFormArray.at(i);
        const paymentRatesDetail = paymentPartiesRateDetail ? paymentPartiesRateDetail.get('PaymentRatesDetail') : null;
        const paymentRates = paymentRatesDetail ? paymentRatesDetail.get('PaymentRates') as FormArray<IPaymentRate> : null;
        if (paymentRates) {
          formArray.push(paymentRates);
        }
      }
    }
    return formArray;
  }

  addPaymentPartyRate(rateTypeId) {
    const paymentRatesFormArrays = this.paymentRateFormArray();

    if (paymentRatesFormArrays?.length) {
      paymentRatesFormArrays.forEach((paymentRates) => {
        paymentRates.push(this.createBlankPaymentRateFormGroup({
          RateTypeId: rateTypeId
        } as IPaymentRate));
      });
    }
  }

  deletePaymentPartyRate(rateTypeId) {
    const paymentRatesFormArrays = this.paymentRateFormArray();

    if (paymentRatesFormArrays?.length) {
      paymentRatesFormArrays.forEach((paymentRates) => {
        const maxLength = paymentRates ? paymentRates.length : 0;
        for (let i = maxLength - 1; i >= 0; i--) {
          const PaymentRate = paymentRates.at(i);
          const RateTypeId = PaymentRate ? PaymentRate.get('RateTypeId') : null;
          const rateTypeIdValue = RateTypeId ? RateTypeId.value : null;
          if (rateTypeIdValue !== null && rateTypeIdValue === rateTypeId) {
            paymentRates.removeAt(i);
          }
        }
      });
    }
  }

  updateCurrenyIdFormControl(index: number, value: number, emitEvent = false) {
    const formGroup = this.paymentPartiesRateDetailFormArray.at(index) as FormGroup<IPaymentPartiesRateDetail>;
    const formControl = formGroup.get('CurrencyId');
    formControl.patchValue(value, { emitEvent });
  }

  updateHoursFormControl(index: number, value, emitEvent = false) {
    const formGroup = this.paymentPartiesRateDetailFormArray.at(index) as FormGroup<IPaymentPartiesRateDetail>;
    const formControl = formGroup.get('Hours');
    formControl.patchValue(value, { emitEvent });
  }

  updateRateUnitIdFormControl(value: number, currentRateTypeId, emitEvent = false) {
    for (let i = 0; i < (this.paymentPartiesRateDetailFormArray ? this.paymentPartiesRateDetailFormArray.length : 0); i++) {
      const fgPaymentPartiesDetail = this.paymentPartiesRateDetailFormArray.at(i);
      const fgPaymentRatesDetail = fgPaymentPartiesDetail ? fgPaymentPartiesDetail.get('PaymentRatesDetail') as FormGroup<IPaymentRatesDetail> : null;
      const faPaymentRate = fgPaymentRatesDetail ? fgPaymentRatesDetail.get('PaymentRates') as FormArray<IPaymentRate> : null;
      for (let j = 0; j < (faPaymentRate ? faPaymentRate.length : 0); j++) {
        const fgPaymentRate = faPaymentRate.at(j) as FormGroup<IPaymentRate>;
        const fgPaymentRateVal = fgPaymentRate.value || {};
        if (fgPaymentRateVal.RateTypeId === currentRateTypeId) {
          fgPaymentRate.get('RateUnitId').patchValue(value, { emitEvent });
        }
      }
    }
  }

  addPaymentContact(index: number, paymentInfo: IPaymentPartiesRateDetail) {
    const formGroup = this.paymentPartiesRateDetailFormArray.at(index) as FormGroup<IPaymentPartiesRateDetail>;
    const contactsGroup = formGroup.get('PaymentContacts') as FormArray<IPaymentContact>;
    contactsGroup.push(this.createBlankPaymentContactFormGroup(paymentInfo));
  }

  addPaymentPaySideDeduction(index: number, paymentInfo: IPaymentPartiesRateDetail) {
    const formGroup = this.paymentPartiesRateDetailFormArray.at(index) as FormGroup<IPaymentPartiesRateDetail>;
    const PaySideDeductionsGroup = formGroup.get('PaymentPaySideDeductions') as FormArray<IPaymentPaySideDeduction>;
    PaySideDeductionsGroup.push(this.createBlankPaymentPaySideDeductionFormGroup(paymentInfo));
  }

  getPaymentInfoes(workOrder: IWorkOrder, rates: Array<IPaymentPartiesRateDetail>): Array<IPaymentInfo> {
    const paymentInfos: any = [];

    if (rates.length) {
      rates.forEach(rate => {
        paymentInfos.push(this.getRatePaymentInfo(workOrder, rate));
      });
    }

    return paymentInfos;
  }

  updatePaymentRateDescription(rateDetailIndex: number, rateTypeId: number, value: string, emitEvent = false) {
    const fgPaymentPartiesDetail = this.paymentPartiesRateDetailFormArray.at(rateDetailIndex);
    const fgPaymentRatesDetail = fgPaymentPartiesDetail ? fgPaymentPartiesDetail.get('PaymentRatesDetail') as FormGroup<IPaymentRatesDetail> : null;
    const faPaymentRate = fgPaymentRatesDetail ? fgPaymentRatesDetail.get('PaymentRates') as FormArray<IPaymentRate> : null;
    for (let j = 0; j < (faPaymentRate ? faPaymentRate.length : 0); j++) {
      const fgPaymentRate = faPaymentRate.at(j) as FormGroup<IPaymentRate>;
      const fgPaymentRateVal = fgPaymentRate.value || {};
      if (fgPaymentRateVal.RateTypeId === rateTypeId) {
        fgPaymentRate.get('Description').patchValue(value, { emitEvent });
      }
    }
  }

  updateSubdivisionIdSourceDetection(paymentInfoes: Array<IPaymentInfo>, emitEvent = false) {
    paymentInfoes.forEach((item, index) => {
      const formGroup = this.paymentPartiesRateDetailFormArray.at(index) as FormGroup<IPaymentPartiesRateDetail>;
      if (formGroup) {
        formGroup.get('SubdivisionIdSourceDeduction').patchValue(item.SubdivisionIdSourceDeduction, { emitEvent });
      }
    });
  }

  private setupOrganizationIdSupplierListeners() {
    if (this.organizationIdSupplierSubscription$ && !this.organizationIdSupplierSubscription$.closed) {
      this.organizationIdSupplierSubscription$.unsubscribe();
      this.organizationIdSupplierSubscription$ = null;
    }

    this.organizationIdSupplierSubscription$ = new Subscription();

    this.paymentPartiesRateDetailFormArray.controls.forEach(group => {
      this.organizationIdSupplierSubscription$.add(this.getOrganizationIdSupplierListener(group as FormGroup<IPaymentPartiesRateDetail>));
    });
  }

  private getOrganizationIdSupplierListener(formGroup: FormGroup<IPaymentPartiesRateDetail>) {
    return formGroup.get('OrganizationIdSupplier').valueChanges
      .pipe(distinctUntilChanged(),
        takeUntil(this.isRootComponentDestroyed$)
      ).subscribe(value => {
        this.organizationIdSupplierChange.next(value);
      });
  }

  private getRatePaymentInfo(workOrder: IWorkOrder, paymentPartiesRateDetail: IPaymentPartiesRateDetail) {
    // logic here is from legacy code, not going to modify it atm.
    if (paymentPartiesRateDetail.Id > 0 && workOrder.WorkOrderVersion.PaymentInfoes.findIndex(a => a.Id === paymentPartiesRateDetail.Id) !== -1) {
      let paymentInfoFromWorkOrder: any = workOrder.WorkOrderVersion.PaymentInfoes.find(a => a.Id === paymentPartiesRateDetail.Id);
      const updatedPaymentInfo = { ...paymentPartiesRateDetail };
      const updatedPaymentRates: any = updatedPaymentInfo.PaymentRatesDetail;
      delete updatedPaymentInfo.PaymentRatesDetail;
      paymentInfoFromWorkOrder = { ...paymentInfoFromWorkOrder, ...updatedPaymentInfo, PaymentRates: updatedPaymentRates.PaymentRates };
      if (!paymentInfoFromWorkOrder.hasOwnProperty('IsDraft') || paymentInfoFromWorkOrder.IsDraft === null) {
        paymentInfoFromWorkOrder.IsDraft = true;
      }
      return paymentInfoFromWorkOrder;
    } else {
      const updatedPaymentInfo = { ...paymentPartiesRateDetail };
      const updatedPaymentRates = updatedPaymentInfo.PaymentRatesDetail;
      delete updatedPaymentInfo.PaymentRatesDetail;
      const data = { ...updatedPaymentInfo, PaymentRates: updatedPaymentRates.PaymentRates };
      if (!data.hasOwnProperty('IsDraft') || data.IsDraft === null) {
        data.IsDraft = true;
      }
      return data;
    }
  }

  private mapWorkOrderToFormData(workorder: IWorkOrder): Array<IPaymentPartiesRateDetail> {
    const paymentPartiesDeatails: Array<IPaymentPartiesRateDetail> = [];
    const billingHour = workorder.WorkOrderVersion.BillingInfoes[0].Hours;
    workorder.WorkOrderVersion.PaymentInfoes.forEach(element => {
      const paymentPartiesRates: IPaymentPartiesRateDetail = {
        Id: element.Id,
        Hours: billingHour != null ? billingHour.toString() : '',
        CurrencyId: element.CurrencyId,
        OrganizationSupplierDisplayName: element.OrganizationSupplierDisplayName,
        IsOrganizationSupplierSubVendor: element.IsOrganizationSupplierSubVendor,
        IsOrganizationIndependentContractorRole: element.IsOrganizationIndependentContractorRole,
        IsOrganizationLimitedLiabilityCompanyRole: element.IsOrganizationLimitedLiabilityCompanyRole,
        OrganizationIdSupplier: element.OrganizationIdSupplier,
        OrganizationRoleTypeId: element.OrganizationRoleTypeId,
        UserProfileIdSupplier: element.UserProfileIdSupplier,
        IsCommissionVacation: element.IsCommissionVacation,
        UserProfileIdWorker: workorder.UserProfileIdWorker,
        PaymentOtherEarnings: element.PaymentOtherEarnings,
        PaymentSourceDeductions: element.PaymentSourceDeductions,
        PaymentContacts: element.PaymentContacts,
        PaymentSalesTaxes: element.PaymentSalesTaxes,
        PaymentInvoices: element.PaymentInvoices,
        IsUseUserProfileWorkerSourceDeduction: element.IsUseUserProfileWorkerSourceDeduction,
        ApplySalesTax: element.ApplySalesTax,
        WorkOrderVersionId: element.WorkOrderVersionId,
        SubdivisionIdSalesTax: element.SubdivisionIdSalesTax,
        JurisdictionId: element.JurisdictionId,
        SubdivisionIdSourceDeduction: element.SubdivisionIdSourceDeduction,
        PaymentRatesDetail: {
          PaymentRates: element.PaymentRates
        },
        PaymentPaySideDeductions: element.PaymentPaySideDeductions
      };
      paymentPartiesDeatails.push(paymentPartiesRates);
    });
    return paymentPartiesDeatails;
  }

  private createPaymentPartiesRateDetailFormArray(paymentPartiesRateDetails: Array<IPaymentPartiesRateDetail>): FormArray<IPaymentPartiesRateDetail> {
    return this.fb.array<IPaymentPartiesRateDetail>(
      paymentPartiesRateDetails.map((paymentPartiesRateDetail: IPaymentPartiesRateDetail, index) => this.createPaymentPartiesRateDetailFormGroup(paymentPartiesRateDetail, index))
    );
  }

  private createPaymentPartiesRateDetailFormGroup(paymentPartiesRateDetail: IPaymentPartiesRateDetail, paymentPartiesRateDetailIndex: number): FormGroup<IPaymentPartiesRateDetail> {
    return this.fb.group<IPaymentPartiesRateDetail>({
      Id: [paymentPartiesRateDetail.Id],
      Hours: [paymentPartiesRateDetail.Hours, [Validators.required]],
      CurrencyId: [
        paymentPartiesRateDetail.CurrencyId,
        PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.PaymentInfoes', 'CurrencyId',[
          Validators.required
        ])
      ],
      OrganizationIdSupplier: [
        paymentPartiesRateDetail.OrganizationIdSupplier,
        paymentPartiesRateDetailIndex > 0 ? [Validators.required] : null
      ],
      OrganizationRoleTypeId: [
        paymentPartiesRateDetail.OrganizationRoleTypeId,
        paymentPartiesRateDetailIndex > 0 ? [Validators.required] : null
      ],
      OrganizationSupplierDisplayName: [paymentPartiesRateDetail.OrganizationSupplierDisplayName],
      IsOrganizationSupplierSubVendor: [paymentPartiesRateDetail.IsOrganizationSupplierSubVendor],
      IsOrganizationIndependentContractorRole: [paymentPartiesRateDetail.IsOrganizationIndependentContractorRole],
      IsOrganizationLimitedLiabilityCompanyRole: [paymentPartiesRateDetail.IsOrganizationLimitedLiabilityCompanyRole],
      UserProfileIdSupplier: [
        paymentPartiesRateDetail.UserProfileIdSupplier,
        PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.PaymentInfoes', 'UserProfileIdSupplier', [
          Validators.required
        ])
      ],
      UserProfileIdWorker: [paymentPartiesRateDetail.UserProfileIdWorker],
      PaymentRatesDetail: this.createPaymentRateDetailFormGroup(paymentPartiesRateDetail.PaymentRatesDetail.PaymentRates),
      PaymentOtherEarnings: [paymentPartiesRateDetail.PaymentOtherEarnings],
      PaymentSourceDeductions: [paymentPartiesRateDetail.PaymentSourceDeductions],
      PaymentContacts: this.createPaymentContactFormArray(paymentPartiesRateDetail.PaymentContacts, paymentPartiesRateDetail),
      PaymentPaySideDeductions: this.createPaymentPaySideDeductionsFormArray(paymentPartiesRateDetail.PaymentPaySideDeductions, paymentPartiesRateDetail),
      PaymentSalesTaxes: [paymentPartiesRateDetail.PaymentSalesTaxes],
      PaymentInvoices: [paymentPartiesRateDetail.PaymentInvoices],
      ApplySalesTax: [paymentPartiesRateDetail.ApplySalesTax],
      SubdivisionIdSalesTax: [paymentPartiesRateDetail.SubdivisionIdSalesTax],
      JurisdictionId: [paymentPartiesRateDetail.JurisdictionId],
      SubdivisionIdSourceDeduction: [paymentPartiesRateDetail.SubdivisionIdSourceDeduction],
      IsUseUserProfileWorkerSourceDeduction: [paymentPartiesRateDetail.IsUseUserProfileWorkerSourceDeduction],
      WorkOrderVersionId: [paymentPartiesRateDetail.WorkOrderVersionId]
    });
  }

  private createBlankPaymentPartiesRateDetailFormGroup(): FormGroup<IPaymentPartiesRateDetail> {
    const paymentPartiesRateDetailFormGroup: FormGroup<IPaymentRatesDetail> = this.paymentPartiesRateDetailFormArray.at(0).get('PaymentRatesDetail') as FormGroup<IPaymentRatesDetail>;
    return this.fb.group<IPaymentPartiesRateDetail>({
      Id: [0],
      IsDraft: [true],
      IsUseUserProfileWorkerSourceDeduction: [true],
      CurrencyId: [null, [Validators.required]],
      Hours: [this.paymentPartiesRateDetailFormArray.at(0).get('Hours').value],
      OrganizationIdSupplier: [null, [Validators.required]],
      UserProfileIdSupplier: [null, [Validators.required]],
      OrganizationRoleTypeId: [null, [Validators.required]],
      ApplySalesTax: [true],
      OrganizationSupplierDisplayName: [null],
      IsOrganizationSupplierSubVendor: [false],
      IsOrganizationIndependentContractorRole: [false],
      IsOrganizationLimitedLiabilityCompanyRole: [false],
      PaymentRatesDetail: this.createBlankPaymentRatesDetailFormGroup(paymentPartiesRateDetailFormGroup.value),
      PaymentOtherEarnings: this.fb.array([]),
      PaymentSourceDeductions: this.fb.array([]),
      PaymentContacts: this.fb.array([]),
      PaymentPaySideDeductions: this.fb.array([]),
      PaymentSalesTaxes: this.fb.array([]),
      PaymentInvoices: this.createDefaultInvoceFormArray(),
      WorkOrderVersionId: [0],
      SubdivisionIdSalesTax: [null],
      JurisdictionId: [null],
      SubdivisionIdSourceDeduction: [null],
      UserProfileIdWorker: null
    });
  }

  private createBlankPaymentRatesDetailFormGroup(paymentPartiesRateDetail: IPaymentRatesDetail): FormGroup<IPaymentRatesDetail> {
    return this.fb.group<IPaymentRatesDetail>({
      PaymentRates: this.createBlankPaymentRateFormArray(paymentPartiesRateDetail.PaymentRates)
    });
  }

  private createBlankPaymentRateFormArray(paymentRates: IPaymentRate[]): FormArray<IPaymentRate> {
    return this.fb.array<IPaymentRate>(
      paymentRates.map((paymentRate: IPaymentRate) => this.createBlankPaymentRateFormGroup(paymentRate))
    );
  }

  private createBlankPaymentRateFormGroup(paymentRate: IPaymentRate): FormGroup<IPaymentRate> {
    const workerLocationId = this.coreDetailFormService.workerLocationIdFormControl.value;
    const defaultDeductionConfig = PhxConstants.DefaultPaymentRateDeductions.find(r => r.RateTypeId === paymentRate.RateTypeId);
    const defaultConfig = defaultDeductionConfig ? defaultDeductionConfig.defaults.find(config => config.SubdivisionId === workerLocationId) : null;
    let isApplyVacation = true;
    let isApplyDeductions = true;

    if (defaultConfig) {
      isApplyVacation = defaultConfig.IsApplyVacation;
      isApplyDeductions = defaultConfig.IsApplyDeductions;
    }

    return this.fb.group<IPaymentRate>({
      PaymentInfoId: 0,
      Id: 0,
      IsDraft: true,
      Rate: [null, Validators.required],
      RateTypeId: [paymentRate.RateTypeId],
      RateUnitId: [paymentRate.RateUnitId],
      SourceId: null,
      IsApplyDeductions: isApplyDeductions,
      IsApplyVacation: isApplyVacation,
      IsApplyStatHoliday: null,
      Description: null
    });
  }

  private createDefaultInvoceFormArray() {
    return this.fb.array<IPaymentInvoice>([
      {
        Id: 0,
        InvoiceTypeId: 1,
        IsDraft: true,
        PaymentInfoId: 0,
        PaymentInvoiceTemplateId: 1,
        PaymentMethodId: 4
      },
      {
        Id: 0,
        InvoiceTypeId: 2,
        IsDraft: true,
        PaymentInfoId: 0
      },
      {
        Id: 0,
        InvoiceTypeId: 3,
        IsDraft: true,
        PaymentInfoId: 0,
        PaymentInvoiceTemplateId: 1,
        PaymentMethodId: 4
      }
    ]);
  }

  private createPaymentRateDetailFormGroup(paymentRates: Array<IPaymentRate>): FormGroup<IPaymentRatesDetail> {
    return this.fb.group<IPaymentRatesDetail>({
      PaymentRates: this.createPaymentRateFormArray(paymentRates)
    });
  }

  private createPaymentRateFormArray(paymentRates: Array<IPaymentRate>): FormArray<IPaymentRate> {
    return this.fb.array<IPaymentRate>(
      paymentRates.map((paymentRate: IPaymentRate) => this.createPaymentRateFormGroup(paymentRate))
    );
  }

  private createPaymentRateFormGroup(paymentRate: IPaymentRate): FormGroup<IPaymentRate> {
    return this.fb.group<IPaymentRate>({
      PaymentInfoId: [paymentRate.PaymentInfoId],
      Id: [paymentRate.Id],
      IsDraft: [paymentRate.IsDraft],
      Rate: [
        paymentRate.Rate,
        Validators.required
      ],
      RateTypeId: [paymentRate.RateTypeId, [Validators.required]],
      RateUnitId: [
        paymentRate.RateUnitId,
        PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.PaymentInfoes.PaymentRates', 'RateUnitId', [
          Validators.required
        ])
      ],
      SourceId: [paymentRate.SourceId],
      IsApplyDeductions: [paymentRate.IsApplyDeductions],
      IsApplyVacation: [paymentRate.IsApplyVacation],
      IsApplyStatHoliday: [paymentRate.IsApplyStatHoliday],
      Description: [paymentRate.Description]
    });
  }

  private createPaymentPaySideDeductionsFormArray(paymentPaySideDeductions: Array<IPaymentPaySideDeduction>, paymentInfo: IPaymentPartiesRateDetail): FormArray<IPaymentPaySideDeduction> {
    const deductionsArray = paymentPaySideDeductions || [];
    return this.fb.array<IPaymentPaySideDeduction>(
      deductionsArray.map((paymentPaySideDeduction: IPaymentPaySideDeduction) => this.createPaymentPaySideDeductionFormGroup(paymentPaySideDeduction, paymentInfo))
    );
  }
  
  private createPaymentPaySideDeductionFormGroup(paymentPaySideDeduction: IPaymentPaySideDeduction | null, paymentInfo: IPaymentPartiesRateDetail): FormGroup<IPaymentPaySideDeduction> {
    return this.fb.group<IPaymentPaySideDeduction>({
      Id: [paymentPaySideDeduction ? paymentPaySideDeduction.Id : 0],
      IsDraft: [paymentPaySideDeduction ? paymentPaySideDeduction.IsDraft : true],
      PaymentInfoId: [paymentPaySideDeduction ? paymentPaySideDeduction.PaymentInfoId : paymentInfo.Id],
      SourceId: [paymentPaySideDeduction ? paymentPaySideDeduction.SourceId : null],
      PaySideDeductionHeaderId: [paymentPaySideDeduction ? paymentPaySideDeduction.PaySideDeductionHeaderId : null,Validators.required],
    });
  }

 
  private createPaymentContactFormArray(paymentContacts: Array<IPaymentContact>, paymentInfo: IPaymentPartiesRateDetail): FormArray<IPaymentContact> {
    return this.fb.array<IPaymentContact>(
      paymentContacts.map((paymentContact: IPaymentContact) => this.createPaymentContactFormGroup(paymentContact, paymentInfo))
    );
  }

  private createPaymentContactFormGroup(paymentContact: IPaymentContact, paymentInfo: IPaymentPartiesRateDetail): FormGroup<IPaymentContact> {
    return this.fb.group<IPaymentContact>({
      Id: [paymentContact ? paymentContact.Id : 0],
      IsDraft: [paymentContact ? paymentContact.IsDraft : true],
      PaymentInfoId: [paymentContact ? paymentContact.PaymentInfoId : paymentInfo.Id],
      SourceId: [paymentContact ? paymentContact.SourceId : null],
      UserProfileId: [
        paymentContact ? paymentContact.UserProfileId : null,
        paymentInfo.OrganizationIdSupplier
          ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.PaymentInfoes.PaymentContacts', 'PaymentReleaseScheduleId', [
            Validators.required
          ])
          : null
      ]
    });
  }

  private createBlankPaymentPaySideDeductionFormGroup(paymentInfo: IPaymentPartiesRateDetail) {
    return this.createPaymentPaySideDeductionFormGroup(null, paymentInfo);
  }

  private createBlankPaymentContactFormGroup(paymentInfo: IPaymentPartiesRateDetail) {
    return this.createPaymentContactFormGroup(null, paymentInfo);
  }

  private updatePaymentPartiesRateDetailFormArray(
    formArray: FormArray<IPaymentPartiesRateDetail>,
    paymentPartiesRateDetails: Array<IPaymentPartiesRateDetail>
  ) {
    if (formArray.length && paymentPartiesRateDetails.length) {
      paymentPartiesRateDetails.forEach((item, index) => {
        const formGroup = formArray.at(index) as FormGroup<IPaymentPartiesRateDetail>;
        if (formGroup) {
          this.updatePaymentPartiesRateDetailFormGroup(formGroup, item, index);
        } else {
          formArray.push(this.createPaymentPartiesRateDetailFormGroup(item, index));
        }
      });
      if (formArray.length > paymentPartiesRateDetails.length) {
        this.clearArray(formArray, paymentPartiesRateDetails.length);
      }
    } else if (paymentPartiesRateDetails.length) {
      const array = this.createPaymentPartiesRateDetailFormArray(paymentPartiesRateDetails);
      array.controls.forEach(group => formArray.push(group));
    } else {
      this.clearArray(formArray);
    }
  }

  private updatePaymentPartiesRateDetailFormGroup(
    formGroup: FormGroup<IPaymentPartiesRateDetail>,
    paymentPartiesRateDetail: IPaymentPartiesRateDetail,
    paymentPartiesRateDetailIndex: number
  ) {

    formGroup.get('Hours').setValidators(
      Validators.required
    );

    formGroup.get('CurrencyId').setValidators(
      PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.PaymentInfoes', 'CurrencyId', [
        Validators.required
      ])
    );

    formGroup.get('OrganizationIdSupplier').setValidators(
      paymentPartiesRateDetailIndex > 0 ? [Validators.required] : null
    );

    formGroup.get('OrganizationRoleTypeId').setValidators(
      paymentPartiesRateDetailIndex > 0 ? [Validators.required] : null
    );

    formGroup.get('UserProfileIdSupplier').setValidators(
      PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.PaymentInfoes', 'UserProfileIdSupplier', [
        Validators.required
      ])
    );

    formGroup.patchValue({
      Id: paymentPartiesRateDetail.Id,
      Hours: paymentPartiesRateDetail.Hours,
      CurrencyId: paymentPartiesRateDetail.CurrencyId,
      OrganizationIdSupplier: paymentPartiesRateDetail.OrganizationIdSupplier,
      OrganizationRoleTypeId: paymentPartiesRateDetail.OrganizationRoleTypeId,
      OrganizationSupplierDisplayName: paymentPartiesRateDetail.OrganizationSupplierDisplayName,
      IsOrganizationSupplierSubVendor: paymentPartiesRateDetail.IsOrganizationSupplierSubVendor,
      IsOrganizationIndependentContractorRole: paymentPartiesRateDetail.IsOrganizationIndependentContractorRole,
      IsOrganizationLimitedLiabilityCompanyRole: paymentPartiesRateDetail.IsOrganizationLimitedLiabilityCompanyRole,
      UserProfileIdSupplier: paymentPartiesRateDetail.UserProfileIdSupplier,
      UserProfileIdWorker: paymentPartiesRateDetail.UserProfileIdWorker,
      PaymentOtherEarnings: paymentPartiesRateDetail.PaymentOtherEarnings,
      PaymentSourceDeductions: paymentPartiesRateDetail.PaymentSourceDeductions,
      PaymentSalesTaxes: paymentPartiesRateDetail.PaymentSalesTaxes,
      PaymentInvoices: paymentPartiesRateDetail.PaymentInvoices,
      ApplySalesTax: paymentPartiesRateDetail.ApplySalesTax,
      SubdivisionIdSalesTax: paymentPartiesRateDetail.SubdivisionIdSalesTax,
      JurisdictionId: paymentPartiesRateDetail.JurisdictionId,
      SubdivisionIdSourceDeduction: paymentPartiesRateDetail.SubdivisionIdSourceDeduction,
      IsUseUserProfileWorkerSourceDeduction: paymentPartiesRateDetail.IsUseUserProfileWorkerSourceDeduction,
      WorkOrderVersionId: paymentPartiesRateDetail.WorkOrderVersionId
    }, { emitEvent: false });

    const paymentRatesDetailFormGroup = formGroup.get('PaymentRatesDetail') as FormGroup<IPaymentRatesDetail>;
    const paymentRatesFormArray = paymentRatesDetailFormGroup.get('PaymentRates') as FormArray<IPaymentRate>;
    this.updatePaymentRateFormArray(paymentRatesFormArray, paymentPartiesRateDetail.PaymentRatesDetail.PaymentRates);

    const paymentContactsFormArray = formGroup.get('PaymentContacts') as FormArray<IPaymentContact>;
    this.updatePaymentContactFormArray(paymentContactsFormArray, paymentPartiesRateDetail.PaymentContacts, paymentPartiesRateDetail);

    const paymentPaySideDeductionsFormArray = formGroup.get('PaymentPaySideDeductions') as FormArray<IPaymentPaySideDeduction>;
    this.updatePaymentPaySideDeductionFormArray(paymentPaySideDeductionsFormArray, paymentPartiesRateDetail.PaymentPaySideDeductions, paymentPartiesRateDetail);

  }

  private updatePaymentRateFormArray(formArray: FormArray<IPaymentRate>, paymentRates: Array<IPaymentRate>) {
    if (formArray.length && paymentRates.length) {
      paymentRates.forEach((item, index) => {
        const formGroup = formArray.at(index) as FormGroup<IPaymentPartiesRateDetail>;
        if (formGroup) {
          this.updatePaymentRateFormGroup(formGroup, item);
        } else {
          formArray.push(this.createPaymentRateFormGroup(item));
        }
      });
      if (formArray.length > paymentRates.length) {
        this.clearArray(formArray, paymentRates.length);
      }
    } else if (paymentRates.length) {
      const array = this.createPaymentRateFormArray(paymentRates);
      array.controls.forEach(group => formArray.push(group));
    } else {
      this.clearArray(formArray);
    }
  }

  private updatePaymentRateFormGroup(formGroup: FormGroup<IPaymentRate>, paymentRate: IPaymentRate) {

    formGroup.get('Rate').setValidators(
      Validators.required
    );

    formGroup.get('RateTypeId').setValidators(
      Validators.required
    );

    formGroup.get('RateUnitId').setValidators(
      Validators.required
    );

    formGroup.patchValue({
      PaymentInfoId: paymentRate.PaymentInfoId,
      Id: paymentRate.Id,
      IsDraft: paymentRate.IsDraft,
      Rate: paymentRate.Rate,
      RateTypeId: paymentRate.RateTypeId,
      RateUnitId: paymentRate.RateUnitId,
      SourceId: paymentRate.SourceId,
      IsApplyDeductions: paymentRate.IsApplyDeductions,
      IsApplyVacation: paymentRate.IsApplyVacation,
      IsApplyStatHoliday: paymentRate.IsApplyStatHoliday,
      Description: paymentRate.Description
    }, { emitEvent: false });
  }

  private updatePaymentContactFormArray(
    formArray: FormArray<IPaymentContact>,
    paymentContacts: Array<IPaymentContact>,
    paymentInfo: IPaymentPartiesRateDetail
  ) {
    if (formArray.length && paymentContacts.length) {
      paymentContacts.forEach((item, index) => {
        const formGroup = formArray.at(index) as FormGroup<IPaymentContact>;
        if (formGroup) {
          this.updatePaymentContactFormGroup(formGroup, item, paymentInfo);
        } else {
          formArray.push(this.createPaymentContactFormGroup(item, paymentInfo));
        }
      });
      if (formArray.length > paymentContacts.length) {
        this.clearArray(formArray, paymentContacts.length);
      }
    } else if (paymentContacts.length) {
      const array = this.createPaymentContactFormArray(paymentContacts, paymentInfo);
      array.controls.forEach(group => formArray.push(group));
    } else {
      this.clearArray(formArray);
    }
  }

  private updatePaymentContactFormGroup(
    formGroup: FormGroup<IPaymentContact>,
    paymentContact: IPaymentContact,
    paymentInfo: IPaymentPartiesRateDetail
  ) {

    formGroup.get('UserProfileId').setValidators(
      paymentInfo.OrganizationIdSupplier
        ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.PaymentInfoes.PaymentContacts', 'PaymentReleaseScheduleId', [
          Validators.required
        ])
        : null
    );

    formGroup.patchValue({
      Id: paymentContact ? paymentContact.Id : 0,
      IsDraft: paymentContact ? paymentContact.IsDraft : true,
      PaymentInfoId: paymentContact ? paymentContact.PaymentInfoId : paymentInfo.Id,
      SourceId: paymentContact ? paymentContact.SourceId : null,
      UserProfileId: paymentContact ? paymentContact.UserProfileId : null
    }, { emitEvent: false });

  }

  private updatePaymentPaySideDeductionFormArray(
    formArray: FormArray<IPaymentPaySideDeduction>,
    paymentPaySideDeduction: Array<IPaymentPaySideDeduction>,
    paymentInfo: IPaymentPartiesRateDetail
  ) {
    if (formArray.length && paymentPaySideDeduction.length) {
      paymentPaySideDeduction.forEach((item, index) => {
        const formGroup = formArray.at(index) as FormGroup<IPaymentPaySideDeduction>;
        if (formGroup) {
          this.updatePaymentPaySideDeductionFormGroup(formGroup, item, paymentInfo);
        } else {
          formArray.push(this.createPaymentPaySideDeductionFormGroup(item, paymentInfo));
        }
      });
      if (formArray.length > paymentPaySideDeduction.length) {
        this.clearArray(formArray, paymentPaySideDeduction.length);
      }
    } else if (paymentPaySideDeduction.length) {
      const array = this.createPaymentPaySideDeductionsFormArray(paymentPaySideDeduction, paymentInfo);
      array.controls.forEach(group => formArray.push(group));
    } else {
      this.clearArray(formArray);
    }
  }

  private updatePaymentPaySideDeductionFormGroup(
    formGroup: FormGroup<IPaymentPaySideDeduction>,
    paymentPaySideDeduction: IPaymentPaySideDeduction,
    paymentInfo: IPaymentPartiesRateDetail
  ) {
    formGroup.patchValue({
      Id: paymentPaySideDeduction ? paymentPaySideDeduction.Id : 0,
      IsDraft: paymentPaySideDeduction ? paymentPaySideDeduction.IsDraft : true,
      PaymentInfoId: paymentPaySideDeduction ? paymentPaySideDeduction.PaymentInfoId : paymentInfo.Id,
      SourceId: paymentPaySideDeduction ? paymentPaySideDeduction.SourceId : null,
      PaySideDeductionHeaderId: paymentPaySideDeduction ? paymentPaySideDeduction.PaySideDeductionHeaderId : null,
    }, { emitEvent: false });
  }

  private clearArray(formArray: FormArray<IPaymentPartiesRateDetail | IPaymentContact | IPaymentRate | IPaymentPaySideDeduction>, count = 0) {
    while (formArray.length !== count && count < formArray.length) {
      formArray.removeAt(count);
    }
  }

  private setupOvertimeFormExemptionListeners(): void {
    const overTimeDetailsFormGroup =(this.formGroup.controls.OverTimeDetails as FormGroup<OvertimeDetails>);
    overTimeDetailsFormGroup.controls.IsOvertimeExempt.valueChanges.pipe(
      takeUntil(this.isRootComponentDestroyed$)
    ).subscribe(isExempt => {
      /** NOTE: if exempt then we dont care about autocalculate OT so disable everything */
      if (isExempt) {
        overTimeDetailsFormGroup.controls.OvertimeExemptionReason.enable({ emitEvent: false });
        overTimeDetailsFormGroup.controls.AutoCalculateOvertime.patchValue(false,{ emitEvent: false });
        /** NOTE: autocalculate overtime could be disabled (work-order-form.service - setupFormListeners) because of timesheet methodology or 'using projects' */
        if(overTimeDetailsFormGroup.controls.AutoCalculateOvertime.enabled) {
          overTimeDetailsFormGroup.controls.AutoCalculateOvertimeExemptionReason.disable({ emitEvent: false });
          overTimeDetailsFormGroup.controls.AutoCalculateOvertimeExemptionReason.patchValue(null, { emitEvent: false });          
        }
      }
      else {
        overTimeDetailsFormGroup.controls.OvertimeExemptionReason.disable({ emitEvent: false });
        overTimeDetailsFormGroup.controls.OvertimeExemptionReason.patchValue(null, { emitEvent: false });
        /** NOTE: if autocalculate is already false ensure we get a override reason */
        /** NOTE: autocalculate overtime could be disabled (work-order-form.service - setupFormListeners) because of timesheet methodology or 'using projects' */
        if(overTimeDetailsFormGroup.controls.AutoCalculateOvertime.value === false &&
          overTimeDetailsFormGroup.controls.AutoCalculateOvertime.enabled
        ) {
          overTimeDetailsFormGroup.controls.AutoCalculateOvertimeExemptionReason.enable({ emitEvent: false });
        }
      }
    });
  }
}
