import { ICommisionAttribution } from '@attributions/attribution.interface';
import { ICommissionRateVersionInfoDto } from '@commission/model';
import { CustomFieldService, PhxConstants } from '@common/index';
import { ICommonListsItem } from '@common/lists';
import { CommandResponse, StateHistory, StateHistoryGroupHeader } from '@common/model';
import { AccessAction } from '@common/model/access-action';
import { IOverrideTimesheetException } from '@common/model/override-timesheet-exception';
import { PhxDocument } from '@common/model/phx-document';
import { FormBuilder } from '@common/ngx-strongly-typed-forms/model';
import { HashModel } from '@common/utility/hash-model';

export interface IFormGroupOnNew {
  formBuilder: FormBuilder;
  customFieldService: CustomFieldService;
}

export interface IFormGroupSetup extends IFormGroupOnNew {
  hashModel: HashModel;
  toUseHashCode: boolean;
}

export interface IWorkorderRouterState {
  Id: number;
  routerPath: PhxConstants.WorkorderNavigationName;
  roleType?: PhxConstants.WorkorderRoleType;
  roleId?: number;
  url: string;
}

export interface IVersion {
  Id: number;
  IsOriginal: boolean;
  StatusId: PhxConstants.WorkOrderVersionStatus;
  VersionNumber: number;
  EffectiveDate: string;
}

export interface IWorkorderValidationError {
  ValidationErrors: Array<string>;
}

export interface IRoot {
  Id: number;
  TabCore: ICoreTabRoot;
  TabAttributions: ICommisionAttribution;
  TabParties: ITabPartiesandRates;
  TabTimeMaterialInvoice: ITabTimeMaterialInvoice;
  TabExpenseInvoice: ITabExpenseInvoice;
  TabTaxes: ITabTaxes;
  TabEarningsAndDeductions: ITabEarningsAndDeductions;
  Footer: IFooter;
  ClientSpecificFields: any;
  TypedClientSpecificFields: ITypedClientSpecificFields
}

export class IReadOnlyStorage {
  readonly IsEditable: boolean;
  readonly IsCommissionsEditable: boolean;
  readonly IsDebugMode: boolean;
  readonly AccessActions: Array<AccessAction>;
  readonly showTerminationSection: boolean;
  readonly isTemplate: boolean;
}

export interface IAssignmentDto {
  Id: number;
  IsDraft: boolean;
  StatusId: PhxConstants.AssignmentStatus;
  WorkOrders: Array<IWorkOrderDto>;
  UserProfileIdWorker: number;
  WorkerSubdivisionId: number;
  OrganizationIdInternal: number;
  StartDate?: string;
  OrganizationCode: string;
  AtsSourceId: number;
  AtsPlacementId: number;
  OnboardingPackageId?: number;
  AccessActions: Array<AccessAction>;
  AccessLevelId: number;
  readonly ReadOnlyStorage: IReadOnlyStorage;
  LastModifiedDateTime?: string;
  AvailableStateActions: Array<number>;
  WorkOrderHeaders: Array<IWorkOrderHeaderDto>;
  UIVersion?: number;
  ExcludeFromBI?: boolean;
}

export interface IWorkOrderHeaderDto {
  Id: number;
  StatusId: number;
  WorkOrderNumber: number;
  WorkOrderVersionHeaders: Array<IWorkOrderVersionHeaderDto>;
  SourceId?: number;
  TerminationTypeId?: number;
}

export interface IWorkOrderVersionHeaderDto {
  Id: number;
  StatusId: number;
  VersionNumber: number;
  EffectiveDate: string;
}

export interface IWorkOrderDto {
  Id: number;
  AssignmentId: number;
  WorkOrderCreationReasonId: number;
  StatusId: number;
  StartDate: string;
  EndDate: string;
  TerminationDate: number;
  IsDraft: boolean;
  WorkOrderPurchaseOrderLines: number;
  WorkOrderNumber: number;
  SourceId: number;
  TransactionHeaderCount: number;
  WorkOrderVersionActiveCount: number;
  WorkOrderVersions?: IWorkOrderVersion[];
  AvailableStateActions: Array<number>;
}

export interface IBillingRate {
  BillingInfoId?: number;
  Id?: number;
  IsDraft?: boolean;
  Rate?: string;
  RateTypeId?: number;
  RateUnitId?: number;
  SourceId?: number;
  Description?: string;
  Billable?: boolean;
}

export interface IBillingRecipient {
  BillingInvoiceId: number;
  DeliveryMethodId?: number;
  FileExportFormatId?: number;
  Id: number;
  IsDraft: boolean;
  RecipientTypeId: number;
  SourceId?: number;
  UserProfileId?: number;
  DeliverToUserProfileId?: number;
  // BrokenRules: BrokenRules;
}

export interface IBillingInvoice {
  Id: number;
  BillingInfoId: number;
  IsDraft: boolean;
  SourceId?: number;
  BillingRecipients?: IBillingRecipient[];
  IsUsesAlternateBilling?: boolean; //
  OrganizatonClientRoleAlternateBillingId?: number;
  InvoiceTypeId?: number;
  BillingFrequencyId?: number;
  BillingInvoiceTemplateId?: number;
  BillingInvoiceTermsId?: number;
  BillingReferenceContactProfileId?: number;
  BillingInvoicePresentationStyleId?: number;
  BillingConsolidationTypeId?: number;
  InvoiceNote1?: string;
  InvoiceNote2?: string;
  InvoiceNote3?: string;
  InvoiceNote4?: string;
  IsChargebleExpenseSalesTax?: boolean;
  IsSalesTaxAppliedOnVmsImport?: boolean;
  BillingTransactionGenerationMethodId?: number;
  ShowBillingInfoNote2?: boolean;
  ShowBillingInfoNote3?: boolean;
  ShowBillingInfoNote4?: boolean;
  // BrokenRules: BrokenRules;
}

export interface IBillingSalesTax {
  Id: number;
  SalesTaxId: number;
  DisplayName: string;
  IsApplied: boolean;
  ratePercentage: number;
  hasNumber: boolean;
}

export interface IContact {
  FullName: string;
  Id: number;
}

export interface IProfilesListForBillingOrganization {
  OrganizationId: number;
  ProfileTypeId: number;
  ContactId: number;
  ProfileStatusId: number;
  Id: number;
  Contact: IContact;
}

export interface IBillingInfo {
  BillingRates?: IBillingRate[];
  BillingInvoices?: IBillingInvoice[];
  BillingSalesTaxes?: IBillingSalesTax[];
  CurrencyId?: number;
  Hours?: number;
  Id?: number;
  IsDraft?: boolean;
  OrganizationIdClient?: number;
  OrganizationClientDisplayName?: string;
  OrganizationClientSalesTaxDefaultId?: number;
  IsClientRateNegotiationEnabled?: boolean;
  RateUnitId?: number;
  SourceId?: number;
  UserProfileIdClient?: number;
  SubdivisionIdSalesTax?: number;
  JurisdictionId?: number;
  WorkOrderVersionId?: number;
  // BrokenRules: BrokenRules;
  profilesListForBillingOrganization?: IProfilesListForBillingOrganization[];
  orgClientAB?: IOrgClientAB[];
}

export interface IOrgClientAB {
  id: number;
  text: string;
  name?: string;
}

export interface IPaymentRate {
  Id?: number;
  IsApplyDeductions?: boolean;
  IsApplyVacation?: boolean;
  IsApplyStatHoliday?: boolean;
  IsDraft?: boolean;
  PaymentInfoId?: number;
  Rate?: string;
  RateTypeId?: number;
  RateUnitId?: number;
  SourceId?: number;
  Description?: string;
}

export interface IPaymentPaySideDeduction {
  Id: number;
  IsDraft: boolean;
  PaymentInfoId: number;
  SourceId?: number;
  PaySideDeductionHeaderId?: number
}

export interface IPaymentInvoice {
  Id: number;
  IsDraft: boolean;
  PaymentInfoId: number;
  InvoiceTypeId: number;
  PaymentReleaseScheduleId?: number;
  PaymentFrequency?: null;
  PaymentInvoiceTemplateId?: number;
  PaymentInvoiceTermsId?: number;
  PaymentMethodId?: number;
  SourceId?: number;
  IsSalesTaxAppliedOnVmsImport?: boolean;
  // BrokenRules: BrokenRules;
}

export interface IPaymentSalesTax {
  Id: number;
  SalesTaxId: number;
  DisplayName: string;
  IsApplied: boolean;
  ratePercentage: number;
  hasNumber: boolean;
}

export interface IPaymentOtherEarning {
  Id: number;
  PaymentOtherEarningTypeId: number;
  UseWorkerProfileVacationSetup: boolean;
  IsApplied: boolean;
  IsAccrued?: boolean;
  RatePercentage?: number;
  SourceId: number;
  PaymentInfoId: number;
}

export interface IProfilesListForPaymentOrganization {
  OrganizationId: number;
  ProfileTypeId: number;
  ContactId: number;
  ProfileStatusId: number;
  Id: number;
  Contact: IContact;
}

export interface IPaymentInfo {
  CurrencyId?: number;
  Hours?: number;
  Id?: number;
  IsDraft?: boolean;
  OrganizationIdSupplier?: number;
  OrganizationRoleTypeId?: number;
  OrganizationSupplierDisplayName?: string;
  IsOrganizationSupplierSubVendor: boolean;
  IsOrganizationIndependentContractorRole: boolean;
  IsOrganizationLimitedLiabilityCompanyRole: boolean;
  PaymentContacts?: IPaymentContact[];
  PaymentRates?: IPaymentRate[];
  PaymentPaySideDeductions?: IPaymentPaySideDeduction[];
  PaymentInvoices?: IPaymentInvoice[];
  PaymentSalesTaxes?: IPaymentSalesTax[];
  PaymentSourceDeductions?: any[];
  PaymentOtherEarnings?: IPaymentOtherEarning[];
  RateUnitId?: number;
  SourceId?: number;
  UserProfileIdSupplier?: number;
  SubdivisionIdSourceDeduction?: number;
  IsUseUserProfileWorkerSourceDeduction?: boolean;
  SubdivisionIdSalesTax?: number;
  JurisdictionId?: number;
  WorkOrderVersionId?: number;
  IsCommissionVacation?: boolean;
  ApplySalesTax?: boolean;
  profilesListForPaymentOrganization?: IProfilesListForPaymentOrganization[];
}

export interface IPaymentContact {
  Id: number;
  IsDraft: boolean;
  PaymentInfoId: number;
  SourceId?: number;
  UserProfileId: number;
}

export interface ITimeSheetApprover {
  Id: number;
  WorkOrderVersionId?: number;
  SourceId: number;
  UserProfileId: number;
  Sequence: number;
  MustApprove: boolean;
  IsDraft: boolean;
  WorkOrderVersion?: any;
}

export interface ICommissionRate {
  CommissionRateHeaderId: number;
  CommissionRateHeaderStatusId: number;
  Description: string;
  DisplayText: string;
  CommissionRateVersions: ICommissionRateVersionInfoDto[];
}

export interface IWorkOrderVersionCommission {
  Id: number;
  UserProfileIdSales: number;
  CommissionRoleId: number;
  IsApplicable: boolean;
  CommissionRateHeaderId: number;
  FullName: string;
  Description: string;
  CommissionRateStatusId: number;
  CommissionRates: Array<ICommissionRate>;
  CommissionRateVersions: ICommissionRateVersionInfoDto[];
  IsHouseAccount: boolean;
}

export interface IJobOwner {
  Id: number;
  UserProfileIdSales: number;
  CommissionRoleId: number;
  IsApplicable: boolean;
  CommissionRateHeaderId: number;
  FullName: string;
  Description: string;
  CommissionRates: Partial<ICommissionRate[]>;
  IsHouseAccount: boolean;
}

export interface IWorkOrderVersion {
  Id: number;
  WorkOrderId: number;

  VmsWorkOrderReference?: any;
  VersionNumber: number;
  CreatedDatetime: string;
  LastModifiedDatetime: string;
  EffectiveDate: string;
  EffectiveToDate: string;

  WorkOrderStartDateState: string;
  WorkOrderEndDateState: string;

  WorkOrderCreationReasonId: number;
  StatusId: number;

  BillingInfoes: IBillingInfo[];
  PaymentInfoes: IPaymentInfo[];
  TimeSheetApprovers: ITimeSheetApprover[];
  ExpenseApprovers: any[];
  WorkOrderVersionCommissions: IWorkOrderVersionCommission[];

  SourceId: number;
  IsDraft: boolean;

  LineOfBusinessId: number;
  AssignedToUserProfileId: number;

  TimeSheetCycleId: number;
  TimeSheetDescription?: any;
  TimeSheetPreviousApprovalRequired: boolean;
  IsSeasonalOrPartTimeTimesheet?: boolean;
  TimeSheetMethodologyId: number;
  TimeSheetApprovalFlowId: number;
  IsTimeSheetUsesProjects: boolean;
  IsTimesheetProjectMandatory?: boolean;
  IsDisplayEstimatedInvoiceAmount: boolean;
  IsDisplayEstimatedPaymentAmount: boolean;

  IsExpenseRequiresOriginal?: boolean;
  IsExpenseUsesProjects?: boolean;
  ExpenseMethodologyId: number;
  ExpenseDescription?: any;
  ExpenseThirdPartyWorkerReference?: any;
  ExpenseApprovalFlowId?: number;

  WorksiteId: number;
  WorkerLocationId?: number;
  WorkLocationTypeId: number;
  HolidayScheduleNameId: number;

  InternalOrganizationDefinition1Id: number;
  InternalOrganizationDefinition2Id?: number;
  InternalOrganizationDefinition3Id?: number;
  InternalOrganizationDefinition4Id?: number;
  InternalOrganizationDefinition5Id?: number;

  WorkerCompensationId: number;

  WCBIsApplied: boolean;
  WCBPositionTitle: string;

  SalesPatternId?: number;
  JobOwnerUsesSupport: boolean;

  HasClientDiscount: boolean;
  HasClientPercentDiscount: boolean;
  HasClientFlatDiscountAmount: boolean;
  HasClientPerHourDiscount: boolean;
  ClientPercentDiscount: number;
  ClientFlatDiscountAmount: number;
  ClientPerHourDiscount: number;
  ClientDiscountDescription: string;

  HasRebate: boolean;
  RebateHeaderId?: number;
  RebateTypeId?: number;
  RebateRate?: number;
  HasVmsFee: boolean;
  VmsFeeHeaderId?: number;
  VmsFeeTypeId?: number;
  VmsFeeRate?: number;
  IsFlowdownFee?: boolean;
  IsEligibleForCommission: boolean;
  IsEligibleForAllowance: boolean;
  IsThirdPartyImport?: boolean;
  CommissionThirdPartyWorkerReference?: any;
  ApplyFlatStatPay?: boolean;
  IsRecruiterRemoved: boolean;

  InitialAskPayRate: number;
  InitialAskBillRate: number;
  FeeSavings: number;
  BurdenSavings: number;
  AdditionalRateSavings: number;
  JobPostingNumber: string;
  RateCardJobTitle: string;
  ClientGroup: string;
  RateNegotiationAdditionalInfo: string;
  MaxBillRate: number;
  BurdenUsedPercentage: number;
  IsRatePrenegotiated: boolean;
  IsRateCardUsed: boolean;
  IsRateWithinRateCard: boolean;
  SubvendorActualPayRate: number;
  SubvendorActualProfileTypeId: number;
  SubvendorActualBurdenCosts: number;
  SubvendorMargin: number;
  IsCommissionChangeImpactApproved: boolean;
  HasApprovedCommissionImpact: boolean;

  JobCategoryId: number;
  JobFunctionId: number;
  JobSkills: Array<IJobSkill>;
  ClientPositionTitle: string;
  IsClientPositionTitleSameAsJobFunction: boolean;

  CNRWithholdingTaxIsApplied: boolean;
  CNRWithholdingTaxOverrideReason: string;

  // UI
  IsDraftStatus: boolean;
  IsComplianceDraftStatus: boolean;
  ValidateComplianceDraft: boolean;
  JobOwner: IJobOwner;
  SupportingJobOwners: any[];
  Recruiters: any[];
  WorkOrderNumber: number;
  AccrueEmployerHealthTaxLiability: boolean;
  WorkOrderPurchaseOrderLines?: Array<IWorkOrderPurchaseOrderLines>;
  wovEndDate: string; // TODO: replace with EffectiveToDate
  ClientBasedEntityCustomFieldValue?: any;
  IsRateNegotiationEnabled: boolean;
  PreviousBillingRate: number;
  PreviousPaymentRate: number;
  IsWovCommissionChanged: boolean;

  CommissionAttribution: ICommisionAttribution;
  OverrideTimesheetExceptions: IOverrideTimesheetException[];

  AutoCalculateOvertime: boolean;
  AutoCalculateOvertimeExemptionReason: string;
  IsOvertimeExempt: boolean | null;
  OvertimeExemptionReason: string;

  // Typed Client Specific Fields: Made for Federal Workers #48310
  IsFederalWorker?: boolean;
  IsControlledGoods?: boolean;
}

export interface IWorkOrder {
  Id?: number;
  AssignmentId: number;
  WorkOrderId: number;
  UserProfileIdWorker: number;
  OrganizationIdInternal: number;
  AssignmentStartDate: string;
  ExcludeFromBI?: boolean;
  StartDate: string;
  EndDate: string;
  AtsPlacementId: number;
  StatusId: number;
  IsDraftStatus: boolean;
  IsExtended: boolean;
  IsOffboarding: boolean;
  HasOffboardingAccess: boolean;
  OffboardingCompletionDate: string;
  TerminationDate: string;
  TerminationNoticeDate: string;
  WorkOrderVersion: IWorkOrderVersion;
  RootObject: Partial<IAssignmentDto>;
  readOnlyStorage?: IReadOnlyStorage;
  WorkorderValidationErrors: CommandResponse;
  workerProfileTypeId?: number;
  workerContactId?: number;
  TransactionHeaderCount?: number;
  WorkOrderVersionActiveCount?: number;
  TemplateId?: number;
  IsPaymentStopped?: boolean;
  AssignmentStatus?: boolean;
  combinedAvailableStateActions: Array<number>;
  LastSubmitedTimesheetStartDate?: Date;
  LastSubmitedTimesheetEndDate?: Date;
  IsClientRateNegotiationEnabled: boolean;
  TerminationTypeId: number;
  TerminationReasons: Array<ITerminationReason>;
  TerminationComment: string;

  WorkOrderHeaders: Array<IWorkOrderHeaderDto>;
  isSourceWOTempLayoff: boolean;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  HasProfileWithUSProvinceTax_NoNeedtoHavePaymentSalesTax: boolean;

  /** TODO: sort this out when backend is finished */
  Attributions: ITabAttributions;

  // UI
  /** @description Commissions used on Source WO + Last Approved WOV, or just Source WOV if no Source WO */
  PreviousCommissions: IWorkOrderVersionCommission[];
  PreviousWorkOrderVersionCommissions: IWorkOrderVersionCommission[];
  LastSavedWorkOrderVersionCommissions: IWorkOrderVersionCommission[];
  IsLastSavedWOVCommImpactApproved: boolean;

  AtsUserProfileIdJobOwner?: number;
  AtsUserProfileIdRecruiter?: number;
  OnboardingPackageId?: number;
}

// #region Core Tab

export interface ICoreTabRoot {
  Id: number;
  Job: ITabCoreJob;
  Details: ITabCoreDetails;
  Commissions: ITabCoreCommissions;
  Collaborators: ITabCoreCollaborators;
}

export interface ITabCoreTerminations {
  TerminationTypeId: number;
  TerminationReasons: Array<ITerminationReason>;
  DateOfNotification: string;
  LastDayOfWork: string;
  Comment: string;
}

export interface ITabCoreDetails {
  AtsPlacementId: number;
  OnboardingPackageId?: number;
  StartDate: string;
  EndDate: string;
  LineOfBusinessId: number;
  InternalOrganizationDefinition1Id?: number;
  InternalOrganizationDefinition2Id?: number;
  InternalOrganizationDefinition3Id?: number;
  InternalOrganizationDefinition4Id?: number;
  InternalOrganizationDefinition5Id?: number;
  WorksiteId: number;
  WorkerLocationId?: number;
  WorkLocationTypeId: number;
  HolidayScheduleNameId: number;
  OrganizationIdInternal: number;
  TerminationDate: string;
  WorkOrderStartDateState: string;
  WorkOrderCreationReasonId: number;
  WorkOrderEndDateState: string;
  wovEndDate: string;
  OriginalStartDate?: string;
  ExcludeFromBI?: boolean;
}

export interface ITabCoreCommissions {
  SalesPatternId: number;
  JobOwnerUsesSupport: boolean;
  JobOwner: IJobOwner;
  SupportingJobOwners: Array<IJobOwner>;
  Recruiters: Array<IJobOwner>;
  IsRecruiterRemoved: boolean;
  WorkOrderVersionCommissions: Array<IJobOwner>;
}

export interface ITabCoreCollaborators {
  AssignedToUserProfileId: number;
}

export interface IJobSkill {
  Id: number;
  JobSkillId: number;
  WorkorderVersionId: number;
}

export interface ITabCoreJob {
  JobCategoryId: number;
  JobFunctionId: number;
  JobSkills: number[];
  ClientPositionTitle: string;
  IsClientPositionTitleSameAsJobFunction: boolean;
}

// #endregion

//#region Attributions tab
export interface ITabAttributions {
  Sales: Array<IAttributionsProfiles>;
  Recruiters: Array<IAttributionsProfiles>;
}
export interface IAttributionsProfiles {
  UserProfileId: number;
  UserProfileTypeId: number;
  UserProfileName: string;
  AttributionShare: number;
}

//#endregion

// #region Parties And Rebates

export interface ITabPartiesandRates {
  TabPartyBillingInfoes?: IBillingPartyInfoes;
  IncentiveCompensation?: IIncentiveCompensation;
  TabPartyPaymentInfoes?: IPaymentPartyInfoes;
  RateNegotiation: IRateNegotiation;
}

export interface IBillingPartyInfoes {
  PartiesRateDetails: Array<IPartiesRateDetail>;
}

export interface IPartiesRateDetail {
  Id?: number;
  UserProfileIdClient?: number;
  OrganizationIdClient?: number;
  Hours?: number;
  CurrencyId?: number;
  OrganizationClientDisplayName?: string;
  BillingRatesDetail?: IBillingRatesDetails;
  RebateAndVMSFee?: IRebateAndVMSFee;
  ClientDiscount?: IClientDiscount;
}

export interface IBillingRatesDetails {
  BillingRates: Array<IBillingRate>;
  selectedRateType?: Array<IBillingRate>;
}

export interface IPaymentPartyInfoes {
  PaymentPartiesRateDetails: Array<IPaymentPartiesRateDetail>;
  OverTimeDetails: OvertimeDetails;
}

export interface OvertimeDetails {
  AutoCalculateOvertime?: boolean;
  AutoCalculateOvertimeExemptionReason: string;
  IsOvertimeExempt: boolean | null;
  OvertimeExemptionReason: string;
}

export interface IPaymentPartiesRateDetail {
  Id?: number;
  OrganizationIdSupplier?: number;
  OrganizationRoleTypeId?: number;
  CurrencyId?: number;
  Hours?: string;
  UserProfileIdSupplier?: number;
  ApplySalesTax?: boolean;
  PaymentRatesDetail?: IPaymentRatesDetail;
  PaymentPaySideDeductions?: IPaymentPaySideDeduction[];
  OrganizationSupplierDisplayName?: string;
  IsOrganizationSupplierSubVendor: boolean;
  IsOrganizationIndependentContractorRole: boolean;
  IsOrganizationLimitedLiabilityCompanyRole: boolean;
  UserProfileIdWorker?: number;
  IsCommissionVacation?: boolean;
  PaymentOtherEarnings?: IPaymentOtherEarning[];
  PaymentSourceDeductions?: IPaymentSourceDeductions[];
  PaymentContacts?: Array<IPaymentContact>;
  PaymentInvoices?: IPaymentInvoice[];
  PaymentSalesTaxes?: IPaymentSalesTax[];
  IsUseUserProfileWorkerSourceDeduction?: boolean;
  IsDraft?: boolean;
  WorkOrderVersionId?: number;
  SubdivisionIdSalesTax?: number;
  JurisdictionId?: number;
  SubdivisionIdSourceDeduction?: number;
}

export interface IPaymentRatesDetail {
  PaymentRates: Array<IPaymentRate>;
}

export interface IAvailableRebates {
  headerId: number;
  description: string;
  lineOfBusinessId: number;
  rate: number;
  rebateTypeId: number;
  type: string;
  versionId: number;
  isFlowdownFee: boolean;
  headerStatusId?: number;
}

export interface IRebateAndVMSFee {
  HasRebate: boolean;
  HasVmsFee: boolean;
  RebateHeaderId: number;
  RebateTypeId: number;
  RebateRate: number;
  VmsFeeHeaderId: number;
  VmsFeeTypeId: number;
  VmsFeeRate: number;
  IsFlowdownFee: boolean;
}

export interface IClientDiscount {
  HasClientDiscount: boolean;
  HasClientPercentDiscount: boolean;
  HasClientFlatDiscountAmount: boolean;
  HasClientPerHourDiscount: boolean;
  ClientPercentDiscount: number;
  ClientFlatDiscountAmount: number;
  ClientPerHourDiscount: number;
  ClientDiscountDescription: string;
  DiscountOptions?: boolean; // dummy UI control to show validation beside label for multiple checkboxes
}

export interface IIncentiveCompensation {
  IsEligibleForCommission: boolean;
  IsThirdPartyImport: boolean;
  CommissionThirdPartyWorkerReference: number;
  IsCommissionVacation?: boolean;
  TabPartiesAndRatesPaymentInfoes: IPaymentInfoes;
  IsEligibleForAllowance: boolean;
}

export interface IRateNegotiation {
  InitialAskPayRate: number;
  InitialAskBillRate: number;
  FeeSavings: number;
  BurdenSavings: number;
  AdditionalRateSavings: number;
  JobPostingNumber: string;
  RateCardJobTitle: string;
  ClientGroup: string;
  RateNegotiationAdditionalInfo: string;
  MaxBillRate: number;
  BurdenUsedPercentage: number;
  IsRatePrenegotiated: boolean;
  IsRateCardUsed: boolean;
  IsRateWithinRateCard: boolean;
  SubvendorActualPayRate: number;
  SubvendorActualProfileTypeId: number;
  SubvendorActualBurdenCosts: number;
  SubvendorMargin: number;
}

// #endregion

// #region Time tab material and expense invoice

export interface ITabTimeMaterialInvoice {
  TabTimeMaterialInvoiceBillingInfoes: IBillingInfoes;
  TabTimeMaterialInvoicePaymentInfoes: IPaymentInfoes;
  TabTimeMaterialInvoiceDetail: ITabTimeMaterialInvoiceDetail;
  TabMaterialTimesheetExceptions: IOverrideTimesheetExceptions;
}

export interface IOverrideTimesheetExceptions {
  OverrideTimesheetExceptions: Array<IOverrideTimesheetException>;
}

export interface IPaymentInfoes {
  PaymentInfoes: Array<IPaymentInfo>;
}

export interface IBillingInfoes {
  BillingInfoes: Array<IBillingInfo>;
}

export interface ITabTimeMaterialInvoiceDetail {
  TimeSheetMethodologyId: number;
  TimeSheetCycleId: number;
  IsSeasonalOrPartTimeTimesheet: boolean;
  TimeSheetApprovers: ITimeSheetApprover[];
  TimeSheetApprovalFlowId: number;
  IsTimeSheetUsesProjects: boolean;
  IsTimesheetProjectMandatory?: boolean;
  VmsWorkOrderReference: string;
  TimeSheetDescription: string;
  OrganizationIdClient?: number;
}

export interface IBillingInvoices {
  BillingInvoices: Array<IBillingInvoice>;
}

export interface IUserProfilePaymentMethod {
  IsPreferred: boolean;
  IsSelected: boolean;
  PaymentMethodTypeId: number;
}

export interface ITabExpenseInvoice {
  TabExpenseInvoiceDetail: ITabExpenseInvoiceDetail;
  TabExpenseInvoiceBillingInfoes: IBillingInfoes;
  TabExpenseInvoicePaymentInfoes: IPaymentInfoes;
}

export interface ITabExpenseInvoiceDetail {
  ExpenseMethodologyId: number;
  ExpenseApprovers: ITabExpenseInvoiceApprovers;
  ExpenseApprovalFlowId?: number;
  IsExpenseUsesProjects: boolean;
  ExpenseThirdPartyWorkerReference: string;
  ExpenseDescription: string;
  OrganizationIdInternal?: number;
  OrganizationIdSuppliers?: Array<ISupplierOrganization>;
  OrganizationIdClients?: Array<IClientOrganization>;
}

export interface ITabExpenseInvoiceApprovers {
  ClientApprover: IExpenseApprover[];
  InternalApprover: IExpenseApprover[];
  SupplierApprover: IExpenseApprover[];
}

export interface ISupplierOrganization {
  OrganizationIdSupplier: number;
}

export interface ISupplierOrganizations {
  OrganizationIdSuppliers: Array<ISupplierOrganization>;
}

export interface IClientOrganization {
  OrganizationIdClient: number;
}

export interface IClientOrganizations {
  OrganizationIdClients: Array<IClientOrganization>;
}

export interface IExpenseApprover {
  Id: number;
  ApproverTypeId: number;
  WorkOrderVersionId?: number;
  SourceId?: number;
  UserProfileId: number;
  Sequence: number;
  MustApprove?: boolean;
  IsDraft?: boolean;
  WorkOrderVersion?: any;
}

// #endregion

// #region Taxes tab

export interface ITabTaxes {
  BillingInfoes: Array<IBillingInfo>;
  PaymentInfoes: Array<IPaymentInfo>;
  OrganizationIdInternal: number;
  StatusId: number;
  ValidateComplianceDraft: boolean;
}

// #endregion

// #region Earnings and deductions tab.

export interface ITabEarningsAndDeductions {
  OtherEarnings: IOtherEarning;
  WorkplaceSafetyInsurance: IWorkplaceSafetyInsurance;
  CanadianNonResidentWithholdingTax: ICanadianNonResidentWithholdingTax;
  StatutoryHoliday: IStatutoryHoliday;
  PaymentInfoes: Array<IPaymentInfoDetails>;
  AccrueEmployerHealthTaxLiability: boolean;
  WorkerProfileTypeId: number;
  WorkerContactId: number;
}

export interface IOtherEarning {
  OtherEarning: IOtherEarnings[];
}

export interface IOtherEarnings {
  PaymentInfoId: number;
  OrganizationIdSupplier: number;
  PaymentOtherEarnings: IPaymentOtherEarning[];
}

export interface IWorkplaceSafetyInsurance {
  WorkerCompensationId: number;
  WCBIsApplied: boolean;
  WCBPositionTitle: string;
}

export interface ICanadianNonResidentWithholdingTax {
  CNRWithholdingTaxIsApplied: boolean;
  CNRWithholdingTaxOverrideReason: string;
}

export interface IStatutoryHoliday {
  ApplyFlatStatPay: boolean;
}

export interface IPaymentInfoDetails {
  PaymentInfoId: number;
  SubdivisionIdSourceDeduction?: number;
  OrganizationIdSupplier: number;
  SourceDeductions: ISourceDeductions;
  PaymentSourceDeductions: Array<IPaymentSourceDeductions>;
}

export interface ISourceDeductions {
  SubdivisionIdSourceDeduction: number;
  IsUseUserProfileWorkerSourceDeduction: boolean;
}

export interface IPaymentSourceDeductions {
  IsApplied: boolean;
  RatePercentage: number;
  RateAmount: number;
  IsOverWritable: boolean;
  SourceDeductionTypeId: number;
  ToShow: boolean;
}

// #endregion

// #region Purchase Order tab

// #region Typed Client Specific Fields

export interface ITypedClientSpecificFields {
  IsFederalWorker?: boolean;
  IsControlledGoods?: boolean;
}

// #endregion

export interface IPurchaseOrder {
  Id: number;
  AmountReserved?: number;
  AmountSpent: number;
  AmountCommited: number;
  Amount: number;
  StatusId: boolean;
  PurchaseOrderLineId: number;
  PurchaseOrderLineNumber: number;
  PurchaseOrderNumber: number;
  PurchaseOrderId: number;
  PurchaseOrderLineEndDate: number;
  PurchaseOrderLineStartDate: Date;
  PurchaseOrderDepletionGroupId: number;
  PurchaseOrderLineStatusId: number;
  PurchaseOrderLineCurrencyId: number;
}

export interface IPurchaseOrderDetails {
  purchaseOrders: Array<IPurchaseOrder>;
}

export interface IWorkOrderPurchaseOrderLines {
  Id?: number;
  PurchaseOrderDepletionGroupId?: number;
  StartDate?: Date;
  EndDate?: Date;
  PurchaseOrderId?: number;
  PurchaseOrderLineId?: number;
  PurchaseOrderNumber?: number;
  PurchaseOrderLineNumber?: number;
  Amount?: number;
  AmountCommited?: number;
  AmountSpent?: number;
  AmountReserved?: number;
  StatusId?: number;
  AmountRemaining?: number;
  NoOfPurchaseOrderTransactions?: number;
  SumSalesTaxVersionRate?: number;
  IsTaxIncluded?: boolean;
  CurrencyCode?: string;
}

export interface IPurchaseOrderLineLists {
  Id: number;
  PurchaseOrderId: number;
  PurchaseOrderDepletionGroupId: number;
  PurchaseOrderNumber: number;
  PurchaseOrderLineNumber: number;
  Amount: number;
  AmountCommited: number;
  AmountSpent: number;
  CurrencyCode: string;
  StatusId: number;
  PurchaseOrderLineStatusName: string;
}

export interface POLineNew {
  Amount: number;
  CurrencyId: number;
  DepletionOptionId: number;
  DepletionGroupId: number;
  Description: string;
  EndDate: number;
  Id: number;
  IsDraft?: boolean;
  IsTaxIncluded: boolean;
  PurchaseOrderId?: number;
  PurchaseOrderNumber?: number;
  PurchaseOrderLineNumber: number;
  PurchaseOrderLineReference: number;
  PurchaseOrderTransactions?: number;
  StartDate: number;
  StatusId?: number;
  WorkOrderPurchaseOrderLines: Array<WOPOLines>;
  CreatedDatetime?: number;
  LastModifiedDatetime?: any;
  PurchaseOrderDepletionGroupId?: number;
  FundCommitted?: number;
  AllocNotes?: string;
}

export interface WOPOLines {
  Id?: number;
  WorkOrderId?: number;
  PurchaseOrderLineId?: number;
  PurchaseOrderLineCurrencyId?: number;
  PurchaseOrderLineStatusId?: number;
  Amount?: number;
  AmountCommited?: number;
  StatusId?: number;
  IsDraft?: boolean;
  IsTaxIncluded: boolean;
  SumSalesTaxVersionRate: number;
  AllocationNote?: string;
  LastModifiedByProfileId?: number;
  LastModifiedByContactName?: string;
  LastModifiedDatetime?: Date;
  CreatedByProfileId?: number;
  CreatedByContactName?: string;
  CreatedDatetime?: number;
  AssignmentId?: number;
  WorkOrderStartDate?: number;
  WorkOrderEndDate?: number;
  WorkOrderNumber?: number;
  WorkOrderFullNumber?: number;
  PurchaseOrderId?: number;
  PurchaseOrderDepletionGroupId?: number;
  PurchaseOrderDescription?: number;
  PurchaseOrderLineEndDate?: number;
  PurchaseOrderLineNumber?: number;
  PurchaseOrderLineStartDate?: null;
  PurchaseOrderNumber?: number;
  OrganizationId?: number;
  OrganizationLegalName?: string;
  AmountAllowed?: number;
  AmountReserved?: number;
  AmountSpent?: number;
  AmountTotal?: number;
  AmountRemaining?: number;
  Rate?: number;
  RateUnitId?: number;
  WorkerName: string;
}

// #endregion

// #region Footer

export interface IFooter {
  EffectiveDate: string;
}

// #endregion

export interface IWorkorderSetup {
  AtsPlacementId: number;
  AtsSourceId: number;
  ImportFromAts: boolean;

  OrganizationIdClient?: number;
  OrganizationClientLegalName?: string;
  UserProfileIdWorker?: number;
  UserProfileWorkerName?: string;
  TemplateId: number;
  LineOfBusinessId: number;
}

export interface IWorkorderNew {
  TemplateId: number;
  AtsSourceId: number;
  AtsPlacementId: number;
  Status: string;
  AtsPlacementType: string;
  LineOfBusinessId: number;
  StartDate?: string;
  EndDate?: string;
  BillingRateUnitId?: number;
  PaymentRateUnitId?: number;
  AtsClientId?: number;
  AtsClientDisplayName?: string;
  AtsWorkerId?: number;
  AtsWorkerName?: string;
  AtsJobOwnerId?: number;
  AtsJobOwnerName?: string;
  AtsRecruiterId?: number;
  AtsRecruiterName?: string;
  SuggestedOrganizationIdClient?: number;
  SuggestedOrganizationClientLegalName?: string;
  SuggestedUserProfileIdWorker?: number;
  SuggestedUserProfileWorkerName?: string;
  MappedOrganizationIdClients: number[];
  MappedUserProfileIdWorker?: number;
  FboJobOwnerUserProfileId: number;
  FboJobOwnerName: string;
  FboJobOwnerIsActive: boolean;
  FboRecruiterUserProfileId: number;
  FboRecruiterName: string;
  FboRecruiterIsActive: boolean;
  BillingRates?: IBillingRateSetUp[];
  PaymentRates?: IPaymentRateSetUp[];
}

export interface IUncheckedOrganizationClientLOBSetUp {
  OrganizationIdClient: number;
  IsUnchecked: boolean;
  LOBName: string;
}
export interface IBillingRateSetUp {
  RateTypeId?: number;
  Rate?: number;
}

export interface IPaymentRateSetUp {
  RateTypeId?: number;
  Rate?: number;
  IsApplyDeductions?: boolean;
  IsApplyVacation?: boolean;
  IsApplyStatHoliday: boolean;
}

export interface IWOSaveTemplate {
  templateName: string;
  templateDescription: string;
}

export interface IOffboardingTaskConfig {
  AllowExemption: boolean;
  IsCompliance: boolean;
  Instructions: string;
  DueDays: number;
  NoOfDaysDueFromToday: number;
  DueDate?: Date;
}

export interface IOffboardingTask {
  Id: number;
  OffboardingTaskId: number;
  Notes?: string;
  ExemptionReason?: string;
  StatusId: number;
  IsLocked: boolean;
  AvailableStateActions: number[];
  // UI properties
  completed: boolean;
  exemption: boolean;
  pending: boolean;
  isCompliance: boolean;
  Documents: Array<PhxDocument>;
  Config: IOffboardingTaskConfig;
  LastStateHistory: StateHistory;
}

export interface IOffboardingTaskDocuments {
  OffboardingTaskId: number;
  WorkOrderId: number;
  OffboardingTasksDocuments: Array<PhxDocument>;
}

export enum ModalType {
  ComplianceType = 1,
  ExemptType = 2
}

export interface WorkOrderOffboardingStateHistory {
  WorkOrderStartOffboardingStateHistory: StateHistory;
  OffboardingTaskStateHistories: Array<StateHistory>;
}

export interface WorkOrderOffboardingStateHistoryGroupHeader
  extends StateHistoryGroupHeader {
  Description: string;
}

export interface WorkOrderOffboardingTaskStateHistory extends StateHistory {
  isOffboardingStateHistory: boolean;
}

export interface ITermination {
  TerminationType: number;
  TerminationReasons: Array<number>;
  TerminationDates: ITerminationDates;
  AdditionalInstruction: string;
}

export interface ITerminationDates {
  PlannedDateOfNotification: Date;
  LastDayOfWork: Date;
}

export interface ITerminationType {
  TerminationTypeId: number;
  TerminationTypeText?: string;
  TerminationReasons: Array<ITerminationReason>;
}

export interface ITerminationReason {
  TerminationReasonId: number;
  TerminationReasonText?: string;
}

export interface AdjustmentNewDto {
  Id: number;
  PlannedReleaseDate: string;
  AssignmentId: number;
  WorkOrderId: number;
  WorkOrderNumber: number;
  WorkOrderVersionId: number;
  OrganizationIdInternal: number;
  OrganizationInternalCode: string;
  OrganizationInternalDisplayName: string;
  WorkerUserProfileId: number;
  WorkerProfileTypeId: number;
  WorkerContactId: number;
  WorkerName: string;
  CurrencyId: number;
  OrganizationInternalApplicationDate: string;
  AdjustmentApplicables: AdjustmentApplicableDto[];
}

export interface AdjustmentApplicableDto {
  isApplied?: boolean;
  ApplicableAdjustmentTypeId: ApplicableAdjustmentType;
  EntityName: string;
  EntityId: number;
  Name: string;
  SalesTaxTypeId: number;
  SourceDeductionTypeId: PhxConstants.SourceDeductionType;
  PaymentOtherEarningTypeId: PhxConstants.PaymentOtherEarningType;
  IsApplicableToEmployee: boolean;
  IsApplicableToEmployer: boolean;
  IsApplicableToClient: boolean;
  EmployeeYtdDeduction: number;
  EmployeeAmount: number;
  EmployerYtdDeduction: number;
  EmployerAmount: number;
  ClientAmount: number;
  IsVacationPayAccured: boolean | null;
  IsSalesTaxNumberAvailableBillSide: boolean;
  IsSalesTaxNumberAvailablePaySide: boolean;
  NewYTDWorkerDeduction?: number;
  NewYTDEmployerDeduction?: number;
}

export interface IWorkOrderResult {
  Id?: number;
  WorkOrder: IWorkOrder;
  WorkerProfiles: Array<ICommonListsItem>;
}

export enum ApplicableAdjustmentType {
  SourceDeductionType = 1,
  SalesTaxType = 2,
  PaymentEarningType = 3
}

export interface IWorkOrderCreateTransactionPreCheckDto {
  WorkOrderId: number;
  WorkOrderVersionId: number;
  CalculatedTransactionDate: Date;
  WorkOrderVersionStartDate: Date;
  WorkOrderVersionEndDate: Date;
}

