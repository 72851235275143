import { DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { MAT_PROGRESS_BAR_DEFAULT_OPTIONS, MatProgressBarDefaultOptions } from '@angular/material/progress-bar';

import { cloneDeep } from 'lodash';
import moment from 'moment';
import { FileItem, FileLikeObject, FileUploader, FileUploaderOptions } from 'ng2-file-upload';
import { v4 as uuidv4 } from 'uuid';

import { ApiService, CommonService, DialogService, PhxToastService } from '@common';
import { CodeValue, PhxConstants, PhxDocumentFileUploadConfiguration, PhxDocumentFileUploaderOptions, PhxDocumentFileUploadFileItemActionEventArg } from '@common/model';
import { PhxLocalizationService } from '@common/services/phx-localization.service';
// TODO: this import couples the component to the compliance module, we should move this to a shared service or find a better way to handle this
import { ComplianceDocumentService } from '@compliance/shared/compliance-document.service';

import { BulkUploadItem } from './models/bulk-upload-item.model';
import { BulkUploadResult } from './models/bulk-upload-result.model';
import { CustomFileItem } from './models/custom-file-item.model';
import { PhxDocumentFileUploadResourceKeys } from './phx-document-file-upload.resource-keys';
import { PhxDocumentFileUploadPreviewModalComponent } from '../phx-document-file-upload-preview-modal/phx-document-file-upload-preview-modal.component';
import { PhxToastType } from '../phx-toast/phx-toast-types';

import HTTPResponseStatus = PhxConstants.HTTPResponseStatus;

const matProgressBarDefaultOptions: MatProgressBarDefaultOptions = {
  color: 'primary',
  mode: 'determinate'
};

@Component({
  selector: 'app-phx-document-file-upload',
  templateUrl: './phx-document-file-upload.component.html',
  styleUrls: ['./phx-document-file-upload.component.less'],
  providers: [ComplianceDocumentService, { provide: MAT_PROGRESS_BAR_DEFAULT_OPTIONS, useValue: matProgressBarDefaultOptions }]
})
export class PhxDocumentFileUploadComponent implements OnInit, OnDestroy, OnChanges {
  @Input() configuration: PhxDocumentFileUploadConfiguration;
  @Input() fileUploaderOptions: PhxDocumentFileUploaderOptions;
  @Input() editable = true;
  @Input() showAddButton = true;
  @Input() docTypeList: CodeValue[] = [];
  @Input() funcValidation?: (queue: any) => Array<any>;
  @Input() insideModal: boolean;
  @Input() isReplaceAndMergeEnabled = false;
  @Output() showUploader: EventEmitter<boolean> = new EventEmitter();
  @Output() completeAll: EventEmitter<boolean> = new EventEmitter();
  @Output() successItem = new EventEmitter<PhxDocumentFileUploadFileItemActionEventArg>();
  @Output() successOnEachItem = new EventEmitter<PhxDocumentFileUploadFileItemActionEventArg>();
  @Input() getCustomDataModel;
  @Input() isDisabled: boolean;
  @Input() isPublicIdRequiredOnFileAfterUpload = true;

  @ViewChild('fileUploadTemplate') fileUploadTemplate: TemplateRef<void>;
  @ViewChild(PhxDocumentFileUploadPreviewModalComponent) previewDocumentModal: PhxDocumentFileUploadPreviewModalComponent;

  uploader: FileUploader;
  hasBaseDropZoneOver: boolean;
  validationMessages: Array<any>;
  disableUploadButton: boolean = true;
  bulkUploadData: BulkUploadItem[] = [];
  bulkUploadDialog: DialogRef;
  isBulkUploadInProgress = false;
  readonly dateFieldId = uuidv4();
  readonly commentFieldId = uuidv4();
  private endpoint = '/command/postfile';
  private bulkUploadEnpoint = '/command/post-batch-file';
  private error: string;
  private url: string;
  private multiDocumentUploadBatchId: string;
  private dialogRef?: DialogRef<void>;

  private isPreviewing = false;
  private complianceData: any;

  constructor(
    private commonService: CommonService,
    private apiService: ApiService,
    private cdr: ChangeDetectorRef,
    private localizationService: PhxLocalizationService,
    private phxToastService: PhxToastService,
    private dialogService: DialogService,
    private complianceDocumentService: ComplianceDocumentService
  ) {
    this.hasBaseDropZoneOver = false;
    this.setNewBatchId();
  }

  ngOnDestroy() {
    this.close(false);
  }

  ngOnInit() {
    this.initUploader();
    this.handleStartUploadButton();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.fileUploaderOptions || changes.configuration) {
      this.initUploader();
      if (changes.configuration) {
        this.handleStartUploadButton();
      }
    }
  }

  handleStartUploadButton() {
    const customConfig = this.configuration?.customUiConfig;
    const hasNoRequiredDate = customConfig?.objectDate?.isRequired && !customConfig?.objectDate?.value;
    const hasNoDocumentType = this.configuration?.documentSubTypes?.length && customConfig?.complianceDocumentSubTypes?.some(f => f.isRequired && !f.value);
    const hasNoComment =
      customConfig?.objectComment?.isRequired &&
      (!customConfig?.objectComment?.value ||
        customConfig?.objectComment?.value?.trim() === '' ||
        customConfig?.objectComment?.value?.length < customConfig?.objectComment?.minlength ||
        customConfig?.objectComment?.value?.length > customConfig?.objectComment?.maxlength);

    this.disableUploadButton = hasNoRequiredDate || hasNoComment || hasNoDocumentType;
  }

  close(initUploaderAgain = true) {
    this.dialogRef?.close();
    if (initUploaderAgain) {
      this.initUploader();
    }
  }

  replacePreviewDocument() {
    this.isPreviewing = false;
    this.showModal(this.fileUploaderOptions);
  }

  showModal(fileUploaderOptions: PhxDocumentFileUploaderOptions = null) {
    if (this.uploader && fileUploaderOptions !== null) {
      this.fileUploaderOptions = fileUploaderOptions;
      this.initUploader();
    }
    this.showUploader.emit();
    this.dialogRef = this.dialogService.showInPopup(this.fileUploadTemplate, {
      disableClose: false,
      panelClass: this.insideModal ? undefined : 'bottom-file-uploader',
      excludeModalClass: !this.insideModal
    });
  }

  fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  upload() {
    let valMessages: Array<any> = [];
    this.validationMessages = [];
    /** NOTE: currently only for \src\app\transaction\vms-management\vms-management.component.html oct/24 */
    if (this.funcValidation) {
      valMessages = this.funcValidation(this.uploader.queue);
    }

    if (valMessages.length > 0) {
      this.validationMessages = valMessages;
      return;
    }

    if (this.isReplaceAndMergeEnabled) {
      this.uploadFilesInBulk();
    } else {
      this.uploadNextItem();
    }
  }

  discardDocument(document: CustomFileItem): void {
    if (document.isUnverified) {
      this.apiService.command('ComplianceDocumentSingleDiscard', { ComplianceDocumentId: document.complianceDocumentId, PublicId: document.publicId }, false);
    }
    document.remove();
    if (this.uploader.queue.length === 0) {
      this.uploader.clearQueue();
    }
  }

  continueDocument(document: CustomFileItem): void {
    document.isUnverified = false;
    document.remove();
    if (!this.areTemperedOrUnverifiedItems()) {
      this.successItem.emit({ item: document, response: document.response, status: HTTPResponseStatus.Ok });
      this.uploader.onCompleteAll();
    }
  }

  private areTemperedOrUnverifiedItems(): boolean {
    return this.uploader.queue.some((item: CustomFileItem) => {
      return item.isTampered || item.isUnverified;
    });
  }

  private setNewBatchId() {
    this.multiDocumentUploadBatchId = uuidv4();
    this.url = this.apiService.urlWithRoom(this.endpoint, this.multiDocumentUploadBatchId);
  }
  private initUploader() {
    this.error = '';
    const url: string = this.url;

    const uploaderOptions: FileUploaderOptions = {
      url,
      filters: [
        {
          name: 'minSize',
          fn: (item?: FileLikeObject): boolean => {
            return item.size > 0;
          }
        }
      ]
    };

    if (this.fileUploaderOptions) {
      this.applyUploaderOptions(uploaderOptions);
    }

    this.uploader = new FileUploader(uploaderOptions);

    this.uploader.onAfterAddingFile = fileItem => this.onAfterAddingFile(fileItem);

    this.uploader.onBeforeUploadItem = fileItem => this.onBeforeUploadItem(fileItem);

    this.uploader.onSuccessItem = (item: CustomFileItem, response, status) => this.handleItemSuccess(item, response, status);

    this.uploader.onErrorItem = (item, response, status) => this.onErrorItem(item, response, status);

    this.uploader.onWhenAddingFileFailed = (item, filter) => this.onWhenAddingFileFailed(item, filter);

    this.uploader.onCompleteAll = () => this.onCompleteAll();
  }

  private applyUploaderOptions(uploaderOptions: FileUploaderOptions) {
    if (this.fileUploaderOptions.maxFileSize > 0) {
      uploaderOptions.maxFileSize = this.fileUploaderOptions.maxFileSize;
    }

    if (this.fileUploaderOptions.queueLimit > 0) {
      uploaderOptions.queueLimit = this.fileUploaderOptions.queueLimit;
    }

    if (this.fileUploaderOptions.allowedMimeType?.length) {
      uploaderOptions.allowedMimeType = this.fileUploaderOptions.allowedMimeType;
    }

    if (this.fileUploaderOptions.allowedFileType?.length) {
      uploaderOptions.allowedFileType = this.fileUploaderOptions.allowedFileType;
    }
  }

  private onAfterAddingFile(item: FileItem) {
    this.trimFileName(item);
    if (this.configuration?.customUiConfig?.complianceDocumentSubTypes) {
      const currentIndexOfItem = this.uploader.getIndexOfItem(item);
      this.configuration.customUiConfig.complianceDocumentSubTypes[currentIndexOfItem] = {
        value: null,
        label: 'Document Type',
        helpBlock: null,
        isRequired: true,
        fileName: item.file.name
      };
      this.handleStartUploadButton();
    }
  }

  private onBeforeUploadItem(item: FileItem) {
    item.withCredentials = false;
    const currentIndexOfItem = this.uploader.getIndexOfItem(item);

    if (!this.configuration) {
      this.error = 'configuration property on phx-document-file-upload is null';
      this.commonService.logError(this.error);
    }

    if (currentIndexOfItem === this.uploader.queue.length - 1 && this.configuration) {
      this.configuration.isFinalDocument = true;
    }

    if (this.configuration?.customUiConfig) {
      this.configuration.customDateTime = this.configuration.customUiConfig.objectDate?.value ? moment(this.configuration.customUiConfig.objectDate?.value).format('YYYY-MM-DD') : null;

      this.configuration.customComment = this.configuration.customUiConfig.objectComment?.value;

      this.configuration.documentTypeId = this.configuration.customUiConfig.objectDocumentType?.value ?? this.configuration.documentTypeId;
      this.configuration.documentSubEntityId = this.configuration.customUiConfig.complianceDocumentSubTypes?.[currentIndexOfItem]?.value ?? this.configuration.documentSubEntityId;
      this.configuration.existingDocumentName =
        this.configuration.existingDocumentName ?? this.configuration.documentSubTypes?.find(({ id }) => id === this.configuration.documentSubEntityId)?.existingDocumentName;
      this.configuration.documentTypeName = this.configuration.documentSubTypes?.find(({ id }) => id === this.configuration.documentSubEntityId)?.name;

      if (!this.configuration.hasRulesetDefined || (this.configuration.hasRulesetDefined && this.isPreviewing)) {
        this.configuration.customUiConfig = null;
      }
    }

    const customJson = typeof this.getCustomDataModel !== 'undefined' ? this.getCustomDataModel() : {};
    this.uploader.options.additionalParameter = { ...(this.configuration ?? {}), customJson: JSON.stringify(customJson), multiDocumentUploadBatchId: this.multiDocumentUploadBatchId };

    /** NOTE: the uploader params are reset to the original params - if we are here and we are previewing then the
     * user has decided to keep the file, and we will upload it again - we need to reset the isPreviewWithCompliance
     * flag so the upload follows the regular path and saves the document
     */
    if (this.isPreviewing) {
      this.uploader.options.additionalParameter.isPreviewWithCompliance = false;
      /** NOTE: we set the complianceData of the previewed document to persist it if the user choose to proceed with the document */
      this.uploader.options.additionalParameter.complianceData = this.complianceData;
      this.isPreviewing = false;
    }

    this.setUploaderAuthToken();
    this.cdr.detectChanges();
  }

  private handleItemSuccess(item: CustomFileItem, response: string, status: number, doContinueUploads = true) {
    const responseJson = JSON.parse(response);
    const { CustomPayload: customPayload } = responseJson.commandResult;
    const isPreviewOnly = customPayload?.IsPreviewWithCompliance ?? false;

    if (isPreviewOnly) {
      try {
        const parsedData = JSON.parse(customPayload.ComplianceData);
        this.complianceData = customPayload.ComplianceData;
        const complianceData = this.complianceDocumentService.cleanComplianceData(parsedData);

        /** NOTE: byte array comes as a base 64 string in the response - turn it back to an array */
        const byteCharacters = atob(customPayload.PdfByteArray);
        const byteArrays = [];
        for (let i = 0; i < byteCharacters.length; i++) {
          byteArrays.push(byteCharacters.charCodeAt(i));
        }
        const byteArray = new Uint8Array(byteArrays);

        const blob = new Blob([byteArray as any], { type: 'application/pdf' });
        /** NOTE: this url will be shown in the preview iframe */
        const fileUrl = URL.createObjectURL(blob);

        /** NOTE: set flag to show we are currently previewing a document we just uploaded */
        this.isPreviewing = true;
        this.previewDocumentModal.open(complianceData, fileUrl, cloneDeep(this.uploader.queue[0]));
      } catch (error) {
        this.commonService.logError(this.localizationService.translate(PhxDocumentFileUploadResourceKeys.documentFileUploadComponent.uploadErrorRefreshListMessage));
      }
    } else {
      if (this.isPublicIdRequiredOnFileAfterUpload && responseJson.publicId === '00000000-0000-0000-0000-000000000000') {
        this.logFileUploadErrors(responseJson);
      } else if (
        customPayload &&
        !customPayload.AllowUploadingTamperedPDFs &&
        (customPayload.ESignedStatusId === PhxConstants.ESignedStatus.SignedTempered || customPayload.ESignedStatusId === PhxConstants.ESignedStatus.SignedUnverified)
      ) {
        item.isSuccess = false;
        item.isError = true;
        item.isTampered = customPayload.ESignedStatusId === PhxConstants.ESignedStatus.SignedTempered;
        item.isUnverified = customPayload.ESignedStatusId === PhxConstants.ESignedStatus.SignedUnverified;
        item.complianceDocumentId = customPayload.ComplianceDocumentId;
        item.publicId = customPayload.PublicId;
        item.response = responseJson;
      } else {
        if (this.isReplaceAndMergeEnabled || (this.uploader.getIndexOfItem(item) === this.uploader.queue.length - 1 && !this.areTemperedOrUnverifiedItems())) {
          const customComment = this.uploader.options?.additionalParameter?.customComment ?? null;
          this.successItem.emit({ item, response: JSON.parse(response), status, customComment });
        }
        this.successOnEachItem.emit({ item, response: JSON.parse(response), status });
      }

      if (doContinueUploads) {
        this.uploadNextItem();
      }
    }
  }

  private onErrorItem(item: FileItem, response: string, status?: number, doContinueUploads = true) {
    if (status === HTTPResponseStatus.Unauthorized) {
      this.apiService.refreshToken().subscribe(() => {
        item.isUploaded = false;
        item.isUploading = false;
        this.uploadNextItem();
      });
    } else {
      const res = JSON.parse(response === '' ? '{}' : response);
      if (res && response) {
        this.error = JSON.stringify(res);
      } else {
        this.error = this.localizationService.translate(PhxDocumentFileUploadResourceKeys.documentFileUploadComponent.uploadErrorMessage, item.file.name, status || '', response);
      }
      this.commonService.logError(this.error);

      if (doContinueUploads) {
        this.uploadNextItem();
      }
    }
  }

  private onWhenAddingFileFailed(item: FileLikeObject, error: any) {
    switch (error.name) {
      case 'fileSize':
        this.error = this.localizationService.translate(
          PhxDocumentFileUploadResourceKeys.documentFileUploadComponent.uploadFailedFileSizeMessage,
          item.name,
          this.fileUploaderOptions.maxFileSize / 1024 / 1024
        );
        break;
      case 'minSize':
        this.error = this.localizationService.translate(PhxDocumentFileUploadResourceKeys.documentFileUploadComponent.invalidFileUploaded);
        break;
      case 'mimeType':
      case 'fileType':
        this.error = this.localizationService.translate(PhxDocumentFileUploadResourceKeys.documentFileUploadComponent.uploadFailedFileTypeMessage);
        break;
      case 'queueLimit':
        this.error = this.localizationService.translate(PhxDocumentFileUploadResourceKeys.documentFileUploadComponent.uploadFailedQueueLimitMessage, this.fileUploaderOptions.queueLimit);
        break;
      default:
        this.error = item?.name
          ? this.localizationService.translate(PhxDocumentFileUploadResourceKeys.documentFileUploadComponent.uploadFailedDefaultWithNameMessage, error.name, item.name)
          : this.localizationService.translate(PhxDocumentFileUploadResourceKeys.documentFileUploadComponent.uploadFailedDefaultMessage, error.name);
    }
    this.commonService.logError(this.error);
  }

  private onCompleteAll() {
    if (!this.anyFileFailedUpload()) {
      this.close();
      this.setNewBatchId();
      this.completeAll.emit();
    }
  }

  private anyFileFailedUpload() {
    return this.uploader.queue.some((x: CustomFileItem) => x.isError || x.isTampered || x.isUnverified);
  }

  private setUploaderAuthToken() {
    this.uploader.authToken = 'Bearer ' + this.commonService.bearerToken();
  }

  uploadAfterPreview(currentFileItem: any) {
    /** NOTE: we want to track when a preview file is uploading */
    this.commonService.setAsIsUploading(currentFileItem?.options?.additionalParameter?.entityId ?? null);
    this.isPreviewing = true;
    this.uploader.queue = [currentFileItem];
    let html = '<div class="gvlogo-for-toast">';
    html += '<div class="gv-logo-container"><img src="/assets/logos/gv-logo.svg"></div>';
    html += '<div class="text-container">Your file upload and analysis is being completed.</div>';
    html += '<div>';

    this.commonService.logWarningNoIcon(html, true);
    this.uploadNextItem();
  }

  removeItem(item: FileItem, index: number) {
    item.remove();
    if (this.configuration?.customUiConfig?.complianceDocumentSubTypes) {
      this.configuration.customUiConfig.complianceDocumentSubTypes.splice(index, 1);
      this.handleStartUploadButton();
    }
  }

  proceedWithBulkUpload(): void {
    this.bulkUploadDialog?.close();
    this.isBulkUploadInProgress = true;
    const formData = new FormData();
    this.bulkUploadData.forEach((file, index) => {
      // Randomly show progress to 5-30
      file.uploadItem.progress = Math.floor(Math.random() * (30 - 5 + 1)) + 5;
      file.uploadItem.isUploading = true;

      Object.entries(file).forEach(([key, value]) => {
        const isTheKeyWhichBackendDoesNotNeed = (key as keyof BulkUploadItem) === 'uploadItem' || (key as keyof BulkUploadItem) === 'existingDocumentName';
        if (isTheKeyWhichBackendDoesNotNeed) {
          return;
        }

        const valueToAttach = typeof value !== 'object' || value instanceof File ? value : JSON.stringify(value);
        const fileName = value instanceof File ? value.name : undefined;
        if (fileName) {
          formData.append(`files[${index}].${key}`, valueToAttach, fileName);
        } else {
          formData.append(`files[${index}].${key}`, valueToAttach);
        }
      });
    });

    let url = this.apiService.urlWithRoom(this.bulkUploadEnpoint, this.multiDocumentUploadBatchId);
    url = url.substring(url.indexOf(this.bulkUploadEnpoint));

    this.apiService.httpPostRequest<BulkUploadResult[]>(url, formData).subscribe({
      next: response => {
        this.animateUploadProgressAndHandleResults(response);
      },
      error: error => {
        this.isBulkUploadInProgress = false;
        this.bulkUploadData.forEach(item => {
          item.uploadItem.progress = 0;
          item.uploadItem.isError = true;
          item.uploadItem.isUploading = false;
          item.uploadItem.isUploaded = false;
          this.onErrorItem(item.uploadItem, JSON.stringify(error), NaN, false);
        });
      }
    });
  }

  private animateUploadProgressAndHandleResults(response: BulkUploadResult[]): void {
    const uploadPromises = this.bulkUploadData.map((item, index) => this.fakeUploadProgressForFile(item, index, response));

    Promise.all(uploadPromises).then(() => {
      this.isBulkUploadInProgress = false;
      const hasSuccessfullyUploadedAllItems = this.bulkUploadData.every(({ uploadItem }) => uploadItem.isUploaded);
      if (hasSuccessfullyUploadedAllItems) {
        this.uploader.progress = 100;
        this.bulkUploadData = [];
        this.bulkUploadDialog?.close();
        this.onCompleteAll();
      } else {
        this.uploader.progress = 0;
      }
    });
  }

  private fakeUploadProgressForFile(item: BulkUploadItem, index: number, response: BulkUploadResult[]): Promise<void> {
    setTimeout(() => {
      item.uploadItem.progress = item.uploadItem.progress + Math.floor(Math.random() * (20 - 5 + 1)) + 5;
    }, index && Math.floor(Math.random() * (300 - 100 + 1)) + 100);

    return new Promise<void>(resolve => {
      setTimeout(() => {
        // Show progress at 100
        item.uploadItem.progress = 100;

        const itemUploadResult = response.find(({ documentSubEntityId, uploadedFileIndex }) =>
          Number.isInteger(uploadedFileIndex)
            ? // If the result contains the index, it means it was not merged
              uploadedFileIndex === index && documentSubEntityId === item.documentSubEntityId
            : // If the result does not contain the index, it is a merge result, we look by the subtype entity id only
              documentSubEntityId === item.documentSubEntityId
        );

        if (itemUploadResult?.commandResult) {
          this.handleItemSuccess(item.uploadItem, JSON.stringify(itemUploadResult), HTTPResponseStatus.Ok, false);
          item.uploadItem.isSuccess = true;
          item.uploadItem.isUploading = false;
          item.uploadItem.isUploaded = true;
          item.uploadItem.isError = false;
        } else {
          item.uploadItem.progress = 0;
          item.uploadItem.isError = true;
          item.uploadItem.isUploading = false;
          item.uploadItem.isUploaded = false;
          this.onErrorItem(item.uploadItem, itemUploadResult?.notificationMessage, HTTPResponseStatus.BadRequest, false);
        }
        resolve();
      }, index * 1000);
    });
  }

  private uploadNextItem() {
    this.cdr.detectChanges();
    const items = this.uploader.getNotUploadedItems();

    if (items && Array.isArray(items) && items.length) {
      const item = items[0];
      this.uploader.uploadItem(item);
    } else {
      this.uploader.onCompleteAll();
    }
  }

  private trimFileName(item: FileItem) {
    if (item?.file?.name?.length) {
      const lastPointPosition = item.file.name.lastIndexOf('.');
      item.file.name = item.file.name.substring(0, lastPointPosition).trim() + item.file.name.substring(lastPointPosition);
    }
  }

  private logFileUploadErrors(responseJson: any) {
    const errors = this.commonService.parseResponseError(responseJson.commandResult);

    if (responseJson.exceptionMessage?.length) {
      this.commonService.logError(responseJson.exceptionMessage);
    } else if (errors.length) {
      let exceptionMessage = errors.map(error => (error.PropertyName ? `<strong>${error.PropertyName}</strong>: ` : '') + error.Message).join('<br />');

      if (errors.length > 1) {
        exceptionMessage = '<br />' + exceptionMessage;
      }

      this.phxToastService.showToast(`Failure for ${responseJson.documentName}: `, exceptionMessage, PhxToastType.danger, null, -1);
    } else {
      this.commonService.logError(this.localizationService.translate(PhxDocumentFileUploadResourceKeys.documentFileUploadComponent.uploadErrorRefreshListMessage));
    }
  }

  private uploadFilesInBulk(): void {
    /** The bulk upload is to be implemented */
    this.bulkUploadData = this.uploader
      .getNotUploadedItems()
      .filter(({ isUploaded }) => !isUploaded)
      .map<BulkUploadItem>(item => {
        // Each on before call alters the additional parameter, sent along the way with the file.
        this.onBeforeUploadItem(item);

        const data = Object.entries(this.uploader.options.additionalParameter).reduce(
          (preparedFile, [parameterKey, parameterValue]) => {
            return { ...preparedFile, [parameterKey]: parameterValue };
          },
          { file: item.file.rawFile, uploadItem: item }
        );
        this.configuration.existingDocumentName = undefined;
        return data;
      })
      .sort((a, b) => a.documentSubEntityId - b.documentSubEntityId);

    this.proceedWithBulkUpload();
  }
}
