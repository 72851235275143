import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, firstValueFrom, lastValueFrom, forkJoin, merge, Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, pairwise, startWith, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';

import { ClientSpecificFieldsFormService } from '@client-specific-fields/client-specific-fields-form.service';
import { TypedClientSpecificFieldsFormService } from '@client-specific-fields/components/typed-client-specific-fields/typed-client-specific-fields-form.service';
import { CodeValueService, CommonService, DialogService } from '@common';
import { ICommonListsItem, IHolidayScheduleName } from '@common/lists';
import { CommonListsObservableService } from '@common/lists/lists.observable.service';
import { ConfigurationService } from '@configuration/service/configuration.service';
import { ContactService } from '@contact/services/contact.service';
import { IOrganization } from '@organization/models';

import { CodeValueGroups, IFormService, PhxConstants } from '../../../common/model';
import { FormBuilder, FormGroup } from '../../../common/ngx-strongly-typed-forms';
import {
  IBillingInfo,
  IPaymentInfo,
  IRoot,
  ITabTimeMaterialInvoiceDetail,
  IWorkOrder,
  IWorkOrderVersion, OvertimeDetails
} from '../../models';
import { WorkOrderDataService } from '../work-order-data.service';
import { WorkorderService } from '../workorder.service';
import { AttributionsFormService } from './attributions-form.service';
import { CoreCommissionFormService } from './core-commission-form.service';
import { CoreDetailFormService } from './core-detail-form.service';
import { CoreTabFormService } from './core-tab-form.service';
import { EarningsDeductionsTabFormService } from './earnings-deductions-tab-form.service';
import { ExpenseInvoiceTabFormService } from './expense-invoice-tab-form.service';
import { FooterFormService } from './footer-form.service';
import { PartyPaymentInfoFormService } from './party-payment-info-form.service';
import { PartyTabFormService } from './party-tab-form.service';
import { TaxesTabFormService } from './taxes-tab-form.service';
import { TimeMaterialInvoiceDetailFormService } from './time-material-invoice-detail-form.service';
import { TimeMaterialInvoiceTabFormService } from './time-material-invoice-tab-form.service';
import { WorkplaceSafetyInsuranceFormService } from './workplace-safety-insurance-form.service';
import { trimFields } from '../../../common/utility/trim-payee-name/trim-fields';

@Injectable()
export class WorkOrderFormService implements IFormService {
  formGroup: FormGroup<IRoot>;
  private isRootComponentDestroyed$: Subject<boolean>;
  private holidayScheduleNames: Array<IHolidayScheduleName>;

  private WorkerProfilesMap: { [id: number]: any; } = {};
  private OrganizationInternalMap: { [id: number]: any; } = {};

  private workOrderSubject: BehaviorSubject<IWorkOrder | undefined> = new BehaviorSubject(null);
  workOrder$: Observable<IWorkOrder> = this.workOrderSubject.asObservable();

  private organizationCache = new Map<number, IOrganization>();
  private localOrganizationHasFederalWorkers$ = new BehaviorSubject(false);

  isCommissionAttributionEnabled = false;
  isCommissionHidden = false;
  workorderWorkerProfileTypeId: number;

  constructor(
    private fb: FormBuilder,
    private commonService: CommonService,
    private dialogService: DialogService,
    private workOrderService: WorkorderService,
    private codeValueService: CodeValueService,
    private commonListsObservableService: CommonListsObservableService,
    private workOrderDataService: WorkOrderDataService,
    private workplaceSafetyInsuranceFormService: WorkplaceSafetyInsuranceFormService,
    private coreTabFormService: CoreTabFormService,
    private coreDetailFormService: CoreDetailFormService,
    private coreCommissionFormService: CoreCommissionFormService,
    private partyTabFormService: PartyTabFormService,
    private timeMaterialInvoiceDetailFormService: TimeMaterialInvoiceDetailFormService,
    private footerFormService: FooterFormService,
    private taxesTabFormService: TaxesTabFormService,
    private partyPaymentInfoFormService: PartyPaymentInfoFormService,
    private timeMaterialInvoiceTabFormService: TimeMaterialInvoiceTabFormService,
    private clientSpecificFieldsFormService: ClientSpecificFieldsFormService,
    private typedClientSpecificFieldsFormService: TypedClientSpecificFieldsFormService,
    private expenseInvoiceTabFormService: ExpenseInvoiceTabFormService,
    private earningsDeductionsTabFormService: EarningsDeductionsTabFormService,
    private attributionsFormService: AttributionsFormService,
    private configurationService: ConfigurationService,
    private contactService: ContactService
  ) {
    this.loadMaps();
    this.loadHolidayScheduleNames();
  }

  private get workOrder() {
    return this.workOrderSubject.value;
  }

  private set workOrder(workOrder: IWorkOrder) {
    this.workOrderSubject.next(workOrder);
  }

  public get organizationHasFederalWorkers$() {
    return this.localOrganizationHasFederalWorkers$.asObservable();
  }

  public set organizationHasFederalWorkers(hasFederalWorkers: boolean) {
    this.localOrganizationHasFederalWorkers$.next(hasFederalWorkers);
  }

  createForm(workorder: IWorkOrder, isDestroyed$: Subject<boolean>) {
    this.isRootComponentDestroyed$ = isDestroyed$;

    this.configurationService.isFeatureActive$([PhxConstants.FeatureFlags.AttributionsTab, PhxConstants.FeatureFlags.HideCommissions]).pipe(
      takeUntil(isDestroyed$)
    ).subscribe(featureFlagState => {
      this.isCommissionAttributionEnabled = featureFlagState[PhxConstants.FeatureFlags.AttributionsTab];
      this.isCommissionHidden = featureFlagState[PhxConstants.FeatureFlags.HideCommissions];
    });

    this.formGroup = this.fb.group<IRoot>({
      Id: [workorder.WorkOrderVersion.Id],
      Footer: this.footerFormService.createForm(workorder, isDestroyed$),
      TabCore: this.coreTabFormService.createForm(workorder, isDestroyed$),
      /** NOTE: attribution tab is controlled by a feature flag configuration */
      TabAttributions: this.isCommissionAttributionEnabled ? this.attributionsFormService.createForm(workorder, isDestroyed$) : null,
      TabParties: this.partyTabFormService.createForm(workorder, isDestroyed$),
      TabTimeMaterialInvoice: this.timeMaterialInvoiceTabFormService.createForm(workorder, isDestroyed$),
      TabTaxes: this.taxesTabFormService.createForm(workorder, isDestroyed$),
      ClientSpecificFields: this.clientSpecificFieldsFormService.createForm(workorder, isDestroyed$),
      TypedClientSpecificFields: this.typedClientSpecificFieldsFormService.createForm(workorder, isDestroyed$),
      TabExpenseInvoice: this.expenseInvoiceTabFormService.createForm(workorder, isDestroyed$),
      TabEarningsAndDeductions: this.earningsDeductionsTabFormService.createForm(workorder, isDestroyed$)
    });

    /** NOTE: the need for some listeners depends on the worker profile type */
    this.workorderWorkerProfileTypeId = workorder.workerProfileTypeId;

    this.setupFormListeners();
    this.workOrder = workorder;

    return this.formGroup;
  }

  destroyForm() {
    this.formGroup = null;
    this.workOrder = null;
    this.holidayScheduleNames = [];
  }

  setupFormListeners() {
    this.isRootComponentDestroyed$.subscribe(() => {
      this.destroyForm();
    });

    this.partyPaymentInfoFormService.formGroup.valueChanges.pipe(
      takeUntil(this.isRootComponentDestroyed$),
      distinctUntilChanged()
    ).subscribe(() => {
      this.taxesTabFormService.updatePaymentInfoForm(this.partyPaymentInfoFormService.formGroupToPartial(this.workOrder));
    });

    if (!this.isCommissionHidden) {
      /** NOTE: set core tab details branch field to the branch of the selected job owner */
      this.coreCommissionFormService.jobOwnerFormGroup.controls.UserProfileIdSales.valueChanges.pipe(
        takeUntil(this.isRootComponentDestroyed$),
        withLatestFrom(this.commonListsObservableService.listUserProfileInternalCommission$())
      ).subscribe(([jobOwnerId, internalProfiles]) => {
        if (!jobOwnerId) {
          this.coreDetailFormService.formGroup.controls.InternalOrganizationDefinition1Id.patchValue(null);
        } else {
          const jobOwnerProfile = internalProfiles?.find(f => f.Id === jobOwnerId);
          if (jobOwnerProfile?.Data?.InternalOrganizationDefinition1Id) {
            this.coreDetailFormService.formGroup.controls.InternalOrganizationDefinition1Id.patchValue(jobOwnerProfile.Data.InternalOrganizationDefinition1Id);
          }
        }
      });

      /** NOTE: set core tab details branch field to the branch of the job owner in the selected sales pattern */
      this.coreCommissionFormService.formGroup.controls.SalesPatternId.valueChanges.pipe(
        filter(salesPatternId => !!salesPatternId),
        switchMap(salesPatternId => this.workOrderService.getSalesPattern(salesPatternId)),
        takeUntil(this.isRootComponentDestroyed$),
        withLatestFrom(this.commonListsObservableService.listUserProfileInternalCommission$(), this.commonListsObservableService.listCommissionSalesPatterns$())
      ).subscribe(([salesPatterns, internalProfiles]) => {
        if (!!salesPatterns.Items.length) {
          const jobOwners = salesPatterns.Items[0].CommissionSalesPatternSupporters
            .filter(obj => {
              return obj.CommissionRoleId === PhxConstants.CommissionRole.JobOwnerRoleWithSupport || obj.CommissionRoleId === PhxConstants.CommissionRole.JobOwnerRoleNoSupport;
            }).map(obj => {
              return { UserProfileId: obj.UserProfileId, CommissionRoleId: obj.CommissionRoleId };
            });

          if (!!jobOwners.length) {
            const jobOwnerProfile = internalProfiles?.find(f => f.Id === jobOwners[0].UserProfileId);
            if (jobOwnerProfile?.Data?.InternalOrganizationDefinition1Id) {
              this.coreDetailFormService.formGroup.controls.InternalOrganizationDefinition1Id.patchValue(jobOwnerProfile.Data.InternalOrganizationDefinition1Id, { emitEvent: false });
            }
          }
        }
      });
    }

    if (this.isCommissionAttributionEnabled) {

      /** NOTE: lob attribution splits need to be updated when WO lob changes */
      this.coreDetailFormService.formGroup.controls.LineOfBusinessId.valueChanges.pipe(
        filter(lobId => !!lobId),
        switchMap(lobId => forkJoin([
          of(lobId),
          this.attributionsFormService.getLineOfBusinessAttributionSplits()
        ])),
        takeUntil(this.isRootComponentDestroyed$),
      ).subscribe(([lobId, lobAttributionSplits]) => {
        const lobCode = this.codeValueService.getCodeValue(lobId, CodeValueGroups.LineOfBusiness);
        const currentLineOfBusinessSplit = lobAttributionSplits.find(f => f.LineOfBusinessCode === lobCode.code);

        /** NOTE: do not update attribution splits if WO is read only */
        if (this.workOrder?.readOnlyStorage?.IsEditable) {
          this.attributionsFormService.formGroup.patchValue({
            SalesAttribution: currentLineOfBusinessSplit?.SalesSplit,
            RecruiterAttribution: currentLineOfBusinessSplit?.RecruiterSplit
          });
        }
      });

      /** NOTE: attribution tab manager list neeeds to be update when branch control on core tab changes */
      this.coreDetailFormService.formGroup.controls.InternalOrganizationDefinition1Id.valueChanges.pipe(
        startWith(this.coreDetailFormService.formGroup.controls.InternalOrganizationDefinition1Id.value),
        filter(branchId => !!branchId),
        switchMap(branchId => forkJoin([
          this.workOrderDataService.getBranchById(branchId),
          this.contactService.getActiveInternalUserProfileList()
        ])),
        takeUntil(this.isRootComponentDestroyed$)
      ).subscribe(([branch, internalUsers]) => {
        const apiBranch = branch as { BranchManagers: { UserProfileInternalId: number; }[]; };
        const managerProfiles = internalUsers.filter(f => apiBranch?.BranchManagers?.map(m => m.UserProfileInternalId).includes(f.Id));

        /** NOTE: do not update manager list if WO is read only */
        if (this.workOrder?.readOnlyStorage?.IsEditable) {
          this.attributionsFormService.updateBranchManagers(managerProfiles?.map(m => ({ UserProfileId: m.Id, FullName: m.Contact.FullName })));
        }
      });

    }
    /** NOTE: if worker is w2 or temp - auto-calculate OT is a WO option */
    const shouldSetAutoCalculateOvertimeFieldUpdates = this.workOrderService.autoCalculateOvertimeIsActiveForWorkorder
      && (this.workorderWorkerProfileTypeId === PhxConstants.UserProfileType.WorkerUnitedStatesW2
        || this.workorderWorkerProfileTypeId === PhxConstants.UserProfileType.WorkerTemp);

    if (shouldSetAutoCalculateOvertimeFieldUpdates) {
      this.setAutoCalculateOvertimeFieldUpdates();
    }

    /** NOTE: listen to 'parties and rates' tab rates list - do we have a rate with a rate unit of 'fixed' */
    this.partyTabFormService.formGroup.controls.TabPartyBillingInfoes?.valueChanges.pipe(
      startWith(this.partyTabFormService.formGroup.controls?.TabPartyBillingInfoes.value),
      takeUntil(this.isRootComponentDestroyed$)
    ).subscribe(billingInfoes => {
      const hasFixed = !!billingInfoes?.PartiesRateDetails?.find(f => !!f.BillingRatesDetail.BillingRates
        .find(rate => rate.RateUnitId === PhxConstants.RateUnit.Fixed));
      /** NOTE: if the WO has a rate with a 'fixed' rate unit then default 'timesheet uses projects' to false */
      this.timeMaterialInvoiceDetailFormService.setHasFixedRateUnit(hasFixed);
    });

    this.footerFormService.setupFormListeners();
    this.coreTabFormService.setupFormListeners();
    this.partyTabFormService.setupFormListeners();
    this.timeMaterialInvoiceTabFormService.setupFormListeners();
    this.taxesTabFormService.setupFormListeners();
    this.clientSpecificFieldsFormService.setupFormListeners();
    this.typedClientSpecificFieldsFormService.setupFormListeners(this.organizationHasFederalWorkers$);
    this.expenseInvoiceTabFormService.setupFormListeners();
    this.earningsDeductionsTabFormService.setupFormListeners();

    this.coreDetailFormService.workSiteIdChange$.pipe(
      debounceTime(100),
      startWith(this.coreDetailFormService.workSiteIdFormControl.value),
      distinctUntilChanged(),
      pairwise(),
      takeUntil(this.isRootComponentDestroyed$)
    ).subscribe(async () => {
      const workOrder = this.formGroupToPartial(this.workOrder);
      await this.onChangeWorksiteId(workOrder);
      this.expenseInvoiceTabFormService.updateBillingInfoes(workOrder);
      this.timeMaterialInvoiceTabFormService.updateBillingInfoes(workOrder);
      this.taxesTabFormService.updateBillingInfoForm(workOrder);
    });

    this.coreDetailFormService.workerLocationIdChange$.pipe(
      debounceTime(100),
      startWith(this.coreDetailFormService.workerLocationIdFormControl.value),
      distinctUntilChanged(),
      pairwise(),
      takeUntil(this.isRootComponentDestroyed$)
    ).subscribe(async ([prev]) => {
      const workOrder = this.formGroupToPartial(this.workOrder);
      await this.onChangeWorkerLocationId(workOrder, prev);
    });

    this.coreDetailFormService.organizationIdInternalChange$.pipe(
      debounceTime(100),
      distinctUntilChanged(),
      takeUntil(this.isRootComponentDestroyed$)
    ).subscribe(async () => {
      const workOrder = this.formGroupToPartial(this.workOrder);
      await this.onChangeOrganizationIdInternal(workOrder);
      this.partyPaymentInfoFormService.updateSubdivisionIdSourceDetection(workOrder.WorkOrderVersion.PaymentInfoes);
      this.earningsDeductionsTabFormService.updateSubdivisionIdSourceDeduction(workOrder.WorkOrderVersion.PaymentInfoes, workOrder);
    });

    merge(
      this.coreDetailFormService.lineOfBusinessIdChange$,
      this.coreDetailFormService.organizationIdInternalChange$,
      this.coreDetailFormService.internalOrganizationDefinition1IdChange$,
      this.coreCommissionFormService.usesSupportChange$,
      this.coreCommissionFormService.userProfileIdSaleChange$,
      this.coreCommissionFormService.recruitersFormArray.valueChanges,
      this.coreCommissionFormService.supportingJobOwnerFormArray.valueChanges
    ).pipe(
      debounceTime(100),
      distinctUntilChanged(),
      withLatestFrom(this.workOrderSubject),
      takeUntil(this.isRootComponentDestroyed$)
    ).subscribe(async ([, workOrder]) => {
      workOrder = {
        ...workOrder,
        ...this.formGroupToPartial(workOrder)
      };
      workOrder = await this.workOrderDataService.updateCommissionRate(workOrder);
      const validateComplianceDraftAndNewWO = workOrder.RootObject.StatusId === PhxConstants.AssignmentStatus.Onboarding || workOrder.WorkOrderVersion.ValidateComplianceDraft;
      this.coreCommissionFormService.updateVersionCommissionFormArray(workOrder.WorkOrderVersion.WorkOrderVersionCommissions, validateComplianceDraftAndNewWO);
      this.workOrder = workOrder;
    });

    this.coreCommissionFormService.salePatternIdChange$.pipe(
      debounceTime(100),
      distinctUntilChanged(),
      withLatestFrom(this.workOrderSubject),
      takeUntil(this.isRootComponentDestroyed$)
    ).subscribe(async ([value, workOrder]) => {
      workOrder = {
        ...workOrder,
        ...this.formGroupToPartial(workOrder)
      };
      workOrder = await this.workOrderDataService.onChangeSalesPattern(workOrder, value);
      this.coreCommissionFormService.updateForm(workOrder);
      this.workOrder = workOrder;
    });

  }

  formGroupToPartial(workOrder: IWorkOrder): IWorkOrder {
    workOrder = this.footerFormService.formGroupToPartial(workOrder);
    workOrder = this.coreTabFormService.formGroupToPartial(workOrder);
    /** NOTE: attribution tab is controlled by a feature flag configuration */
    if (this.isCommissionAttributionEnabled) {
      workOrder = this.attributionsFormService.formGroupToPartial(workOrder);
    }
    workOrder = { ...this.partyTabFormService.formGroupToPartial(workOrder) };
    workOrder = { ...this.timeMaterialInvoiceTabFormService.formGroupToPartial(workOrder) };
    workOrder = { ...this.expenseInvoiceTabFormService.formGroupToPartial(workOrder) };
    workOrder = this.taxesTabFormService.formGroupToPartial(workOrder);
    workOrder = this.earningsDeductionsTabFormService.formGroupToPartial(workOrder);
    workOrder = this.clientSpecificFieldsFormService.formGroupToPartial(workOrder);
    workOrder = this.typedClientSpecificFieldsFormService.formGroupToPartial(workOrder);

    workOrder = { ...workOrder, WorkOrderVersion: trimFields(workOrder.WorkOrderVersion, ['VmsWorkOrderReference', 'ExpenseThirdPartyWorkerReference']) };
    return workOrder;
  }

  updateForm(workorder: IWorkOrder) {
    this.formGroup.get('Id').setValue(workorder.WorkOrderVersion.Id, { emitEvent: false });
    this.footerFormService.updateForm(workorder);
    this.coreTabFormService.updateForm(workorder);
    /** NOTE: attribution tab is controlled by a feature flag configuration */
    if (this.isCommissionAttributionEnabled) {
      this.attributionsFormService.updateForm(workorder);
    }
    this.partyTabFormService.updateForm(workorder);
    this.timeMaterialInvoiceTabFormService.updateForm(workorder);
    this.taxesTabFormService.updateForm(workorder);
    this.clientSpecificFieldsFormService.updateForm(workorder);
    this.typedClientSpecificFieldsFormService.updateForm(workorder);
    this.expenseInvoiceTabFormService.updateForm(workorder);
    this.earningsDeductionsTabFormService.updateForm(workorder);

    this.formGroup.updateValueAndValidity({ emitEvent: false });
    this.formGroup.markAsPristine();
    this.workOrder = workorder;
  }

  setWorkerProfiles(workerProfiles: Array<ICommonListsItem>) {
    this.WorkerProfilesMap = workerProfiles.reduce((acc, val) => {
      acc[val.Id] = val;
      return acc;
    }, {});
  }

  getWorkerProfile(userProfileIdWorker: number) {
    if (Object.keys(this.WorkerProfilesMap).length) {
      return this.WorkerProfilesMap[userProfileIdWorker];
    }
    return null;
  }

  getWorkOrderOrganization(organizationId: number): Observable<IOrganization> {
    if (!this.organizationCache.has(organizationId)) {
      return this.workOrderDataService.getOrganizationLatestVersion(organizationId, false).pipe(
        tap(organization => {
          this.organizationCache.clear();
          this.organizationCache.set(organizationId, organization);
        })
      );
    }
    else {
      return of(this.organizationCache.get(organizationId));
    }
  }

  private loadMaps() {
    // assign organizations to a map
    this.commonListsObservableService.listOrganizationInternals$()
      .subscribe(response => {
        const list = response ? response.map(item => item.Data) : [];
        if (list.length) {
          this.OrganizationInternalMap = list.reduce((acc, val) => {
            acc[val.Id] = val;
            return acc;
          }, {});
        }
      });
  }

  public loadHolidayScheduleNames() {
    this.commonListsObservableService.listHolidayScheduleName$()
      .subscribe((schedules: ICommonListsItem[]) => {
        this.holidayScheduleNames = schedules ? schedules.map(item => item.Data) : [];
      });
  }

  private async onChangeOrganizationIdInternal(workOrder: IWorkOrder): Promise<void> {
    const profile = this.WorkerProfilesMap[workOrder.UserProfileIdWorker];
    const org = this.OrganizationInternalMap[workOrder.OrganizationIdInternal];

    await this.updateWorkerClassification(workOrder, 0, workOrder.WorkOrderVersion.WorkerLocationId);

    if ([PhxConstants.UserProfileType.WorkerTemp, PhxConstants.UserProfileType.WorkerCanadianSp, PhxConstants.UserProfileType.WorkerUnitedStatesW2].indexOf(profile.ProfileTypeId) === -1) {
      // not of the allowed profile types
      return;
    }

    // Compare If the worker subdivisionId matches the organization passed
    const FindDefaultSourceDeductionSubdivision = (workerSub: number, organization: any): number => {
      // if the country of the head office is outside of canada don't default;
      if (organization.OrganizationAddresses.find(i => i.IsPrimary).CountryId !== PhxConstants.CountryCanada) {
        return null;
      }

      for (const organizationAddress of organization.OrganizationAddresses) {
        if (organizationAddress.SubdivisionId === workerSub) {
          return workerSub; // match found so return the workerSub
        }
      }

      // no match found at this point so return the sub id of the head office of the org
      return organization.OrganizationAddresses.find(i => i.IsPrimary).SubdivisionId;
    };

    let subdivisionIdSourceDeduction = null;
    // determine where to get the tax province from for the worker
    if (org) {
      if (profile.TaxSubdivisionId) {
        subdivisionIdSourceDeduction = FindDefaultSourceDeductionSubdivision(profile.TaxSubdivisionId, org);
      } else if (workOrder.RootObject.WorkerSubdivisionId) {
        subdivisionIdSourceDeduction = FindDefaultSourceDeductionSubdivision(workOrder.RootObject.WorkerSubdivisionId, org);
      }
    }

    // assign the new subdivision id to each payment info on the wov
    workOrder.WorkOrderVersion.PaymentInfoes.forEach(i => i.SubdivisionIdSourceDeduction = subdivisionIdSourceDeduction);
  }

  private async onChangeWorkerLocationId(workOrder: IWorkOrder, oldWorkerLocationId: number): Promise<void> {
    const workOrderVersion: IWorkOrderVersion = workOrder ? workOrder.WorkOrderVersion : null;
    if (workOrderVersion) {
      const workerLocationChangedMessages = [];

      await this.updateHolidaySchedule(workOrder, workerLocationChangedMessages);
      await this.updateWorkerClassification(workOrder, oldWorkerLocationId, workOrderVersion.WorkerLocationId, workerLocationChangedMessages);
      await this.updateCanadianNonResidentWithholdingTaxFields(workOrder, workerLocationChangedMessages);

      const billingInfo = workOrderVersion.BillingInfoes ? workOrderVersion.BillingInfoes[0] : null;
      const organizationClientSalesTaxDefaultId = billingInfo ? billingInfo.OrganizationClientSalesTaxDefaultId : null;

      if (organizationClientSalesTaxDefaultId !== PhxConstants.ClientSalesTaxDefault.HeadOffice &&
        workOrder.workerProfileTypeId === PhxConstants.UserProfileType.WorkerTemp &&
        this.changePaymentRates(workOrderVersion.PaymentInfoes, workOrderVersion.WorkerLocationId)) {
        workerLocationChangedMessages.push('The default setting for applying vacation pay has been updated.');
        this.partyPaymentInfoFormService.updateForm(workOrder);
      }

      if (workerLocationChangedMessages.length > 0) {
        this.dialogService.notify('The Consultant Work Location has Changed', workerLocationChangedMessages.join('<br>'), { backdrop: 'static', size: 'md' });
      }
    }
  }

  private isHolidayScheduleSubdivsion(subdivisionId: number): boolean {
    if (subdivisionId) {
      const countryId = this.codeValueService.getCodeValue(subdivisionId, 'geo.CodeSubdivision').parentId;
      return countryId === PhxConstants.CountryCanada || countryId === PhxConstants.CountryUSA;
    }
    return false;
  }

  private async onChangeWorksiteId(workOrder: IWorkOrder): Promise<void> {
    const workOrderVersion: IWorkOrderVersion = workOrder ? workOrder.WorkOrderVersion : null;
    if (workOrderVersion) {
      const worksiteChangedMessage = [];
      const billingInfo = workOrderVersion.BillingInfoes ? workOrderVersion.BillingInfoes[0] : null;
      const organizationClientSalesTaxDefaultId = billingInfo ? billingInfo.OrganizationClientSalesTaxDefaultId : null;
      const subdivisionIdSalesTax = billingInfo ? billingInfo.SubdivisionIdSalesTax : null;
      const worksiteId = workOrderVersion.WorksiteId;
      const subdivisionIdByWorksite = this.workOrderService.getSubdivisionIdByWorksiteId(worksiteId);

      if (subdivisionIdByWorksite) {
        if (organizationClientSalesTaxDefaultId !== PhxConstants.ClientSalesTaxDefault.HeadOffice) {
          //  when organizationClient is head office, then any changes of Worksite will NOT effect any messages or changes of billingInfo.SubdivisionIdSalesTax
          if (!subdivisionIdSalesTax && billingInfo.OrganizationIdClient) {
            await this.onChangeOrganizationIdClient(workOrder, billingInfo);
          } else {
            if (subdivisionIdSalesTax && subdivisionIdSalesTax !== subdivisionIdByWorksite) {
              const subdivisionByWorksiteName = this.codeValueService.getCodeValueText(subdivisionIdByWorksite, CodeValueGroups.Subdivision);
              const subdivisionSalesTaxName = this.codeValueService.getCodeValueText(subdivisionIdSalesTax, CodeValueGroups.Subdivision);
              worksiteChangedMessage.push('The Client Sales Tax Territory has been updated from "' + subdivisionSalesTaxName + '" to "' + subdivisionByWorksiteName + '"');
            }
            this.taxesTabFormService.billingInfoesFormArray.at(0).get('SubdivisionIdSalesTax').setValue(subdivisionIdByWorksite);
          }
        }
      }

      await this.updateCanadianNonResidentWithholdingTaxFields(workOrder, worksiteChangedMessage);

      if (worksiteChangedMessage.length > 0) {
        this.dialogService.notify('The Client Worksite Province has Changed', worksiteChangedMessage.join('<br>'), { backdrop: 'static', size: 'md' });
      }
    }
  }

  private async updateCanadianNonResidentWithholdingTaxFields(workOrder: IWorkOrder, changedMessages: any[]) {
    const workOrderVersion: IWorkOrderVersion = workOrder.WorkOrderVersion;
    if (workOrderVersion.CNRWithholdingTaxIsApplied !== null) {
      const withholdingTaxStillApplicable = await lastValueFrom(this.workOrderService.isCanadianNonResidentWithholdingTaxApplicable(workOrder));

      if (!withholdingTaxStillApplicable) {
        workOrderVersion.CNRWithholdingTaxIsApplied = null;
        workOrderVersion.CNRWithholdingTaxOverrideReason = null;
        this.earningsDeductionsTabFormService.cNRWithholdingTaxIsAppliedFormControl.setValue(null);

        changedMessages.push('Canadian Non-Resident Withholding Tax is no longer applicable.');
      }
    } else if (workOrderVersion.CNRWithholdingTaxIsApplied === null) {
      const withholdingTaxApplicable = await lastValueFrom(this.workOrderService.isCanadianNonResidentWithholdingTaxApplicable(workOrder));

      if (withholdingTaxApplicable) {
        workOrderVersion.CNRWithholdingTaxIsApplied = true;
        this.earningsDeductionsTabFormService.cNRWithholdingTaxIsAppliedFormControl.setValue(true, { emitEvent: true });
      } 
    }
  }

  private async updateHolidaySchedule(workOrder: IWorkOrder, changedMessages: any[]): Promise<void> {
    const { WorkOrderVersion } = workOrder;
    const billingInfo = WorkOrderVersion.BillingInfoes ? WorkOrderVersion.BillingInfoes[0] : null;
    const { WorkerLocationId } = WorkOrderVersion;

    if (WorkerLocationId && this.isHolidayScheduleSubdivsion(WorkerLocationId)) {
      let useDefaultSchedule = false;

      const response = await firstValueFrom(this.commonListsObservableService.listDetailedOrganizationClients$().pipe(filter(res => res != null)));

      if (response) {
        const listOrganizationClient = response.map(i => i.Data);
        const organizationIdClient = billingInfo ? billingInfo.OrganizationIdClient : null;
        const organizationClient = listOrganizationClient ? listOrganizationClient.find(o => o.Id === organizationIdClient) : null;

        if (organizationClient?.OrganizationClientRoles) {
          const clientHolidayScheduleDefaultId = organizationClient.OrganizationClientRoles[0].HolidayScheduleDefaultId;
          if (clientHolidayScheduleDefaultId) {
            if (WorkOrderVersion.HolidayScheduleNameId !== clientHolidayScheduleDefaultId) {
              WorkOrderVersion.HolidayScheduleNameId = clientHolidayScheduleDefaultId;
              changedMessages.push('The Holiday Schedule has been updated.');
            }
          } else {
            useDefaultSchedule = true;
          }
        } else {
          useDefaultSchedule = true;
        }
      } else {
        useDefaultSchedule = true;
      }

      if (useDefaultSchedule) {
        const defaultSchedule = this.holidayScheduleNames.find(i => i.DefaultSubdivisionId === WorkerLocationId && i.IsActive === true);
        if (defaultSchedule) {
          WorkOrderVersion.HolidayScheduleNameId = defaultSchedule.HolidayScheduleNameId;
          changedMessages.push('The Holiday Schedule has been updated.');
        } else {
          WorkOrderVersion.HolidayScheduleNameId = null;
        }
      }

    } else {
      WorkOrderVersion.HolidayScheduleNameId = null;
    }

    this.coreDetailFormService.updateHolidayScheduleNameId(WorkOrderVersion.HolidayScheduleNameId);
  }

  private async updateWorkerClassification(workOrder: IWorkOrder, oldSubdivisionId: number, subdivisionId: number, changedMessages?: any[]): Promise<void> {
    const { WorkOrderVersion } = workOrder;
    if (oldSubdivisionId !== subdivisionId) {
      const checkEarningValidate = await this.workplaceSafetyInsuranceFormService.getValidatorForWorkerCompensation(workOrder);
      if (changedMessages && (WorkOrderVersion.WorkerCompensationId || this.workplaceSafetyInsuranceFormService.checkEarningvalidate !== checkEarningValidate)) {
        // notify user when (1) WCB was selected, or (2) field mandatory settings changed
        changedMessages.push('The Workplace Safety Insurance Worker Classification list has been updated.');
      }
      if (checkEarningValidate) {
        this.workplaceSafetyInsuranceFormService.setWorkerCompensationByWorkerLocation(subdivisionId, workOrder.OrganizationIdInternal, workOrder.workerProfileTypeId);
      }
      else {
        WorkOrderVersion.WCBIsApplied = null;
        WorkOrderVersion.WorkerCompensationId = null;
      }
      this.workplaceSafetyInsuranceFormService.checkEarningvalidate = checkEarningValidate;
      this.workplaceSafetyInsuranceFormService.updateForm(workOrder);
    }
  }

  private async onChangeOrganizationIdClient(workOrder: IWorkOrder, billingInfo: IBillingInfo): Promise<void> {
    const response = await firstValueFrom(this.commonListsObservableService.listDetailedOrganizationClients$().pipe(filter(res => res != null)));
    if (response) {
      const listOrganizationClient = response.map(i => i.Data);
      const workOrderVersion = workOrder ? workOrder.WorkOrderVersion : null;
      const organizationIdClient = billingInfo ? billingInfo.OrganizationIdClient : null;
      const organizationClient = listOrganizationClient ? listOrganizationClient.find(o => o.Id === organizationIdClient) : null;

      if (workOrderVersion && organizationClient) {
        billingInfo.OrganizationClientDisplayName = organizationClient.DisplayName;

        //  http://tfs:8080/tfs/DefaultCollection/Development/_workitems#_a=edit&id=15523
        if (!organizationClient.OrganizationClientRoles || organizationClient.OrganizationClientRoles.length !== 1) {
          return Promise.reject('Client Organization MUST have ONE OrganizationClientRole');
        }

        if (organizationClient.OrganizationClientRoles[0].IsChargeSalesTax === true) {
          if (organizationClient.OrganizationClientRoles[0].ClientSalesTaxDefaultId === PhxConstants.ClientSalesTaxDefault.HeadOffice) {
            const organizationAddressPrimary = organizationClient.OrganizationAddresses.find(address => address.IsPrimary);
            if (organizationAddressPrimary) {
              this.taxesTabFormService.billingInfoesFormArray.at(0).get('SubdivisionIdSalesTax').setValue(organizationAddressPrimary.SubdivisionId);
            } else {
              this.commonService.logError('Client Organization MUST have Primary Address');
              this.taxesTabFormService.billingInfoesFormArray.at(0).get('SubdivisionIdSalesTax').setValue(0);
            }
          } else if (organizationClient.OrganizationClientRoles[0].ClientSalesTaxDefaultId === PhxConstants.ClientSalesTaxDefault.WorkOrderWorksite) {
            const subdivisionIdSalesTax = this.workOrderService.getSubdivisionIdByWorksiteId(workOrderVersion.WorksiteId);
            this.taxesTabFormService.billingInfoesFormArray.at(0).get('SubdivisionIdSalesTax').setValue(subdivisionIdSalesTax);
          } else {
            this.commonService.logError('Client Organization ClientSalesTaxDefaultId "' + organizationClient.OrganizationClientRoles[0].ClientSalesTaxDefaultId + '" does NOT supported');
          }
        } else {
          const subdivisionIdSalesTax = this.workOrderService.getSubdivisionIdByWorksiteId(workOrderVersion.WorksiteId);
          this.taxesTabFormService.billingInfoesFormArray.at(0).get('SubdivisionIdSalesTax').setValue(subdivisionIdSalesTax);
        }

        if (organizationClient.OrganizationClientRoles[0].UsesThirdPartyImport) {
          workOrderVersion.TimeSheetMethodologyId = PhxConstants.ExpenseMethodology.ThirdPartyImport;
          if (billingInfo.BillingInvoices?.length) {
            billingInfo.BillingInvoices.forEach(billingInvoice => {
              if (billingInvoice.InvoiceTypeId === PhxConstants.InvoiceType.Expense) {
                billingInvoice.IsSalesTaxAppliedOnVmsImport = organizationClient.OrganizationClientRoles[0].IsBillSalesTaxAppliedOnExpenseImport;
              }
            });
          }
          if (workOrderVersion.PaymentInfoes?.length) {
            workOrderVersion.PaymentInfoes.forEach(paymentInfo => {
              if (paymentInfo?.PaymentInvoices?.length) {
                paymentInfo.PaymentInvoices.forEach(paymentInvoice => {
                  if (paymentInvoice.InvoiceTypeId === PhxConstants.InvoiceType.Expense) {
                    paymentInvoice.IsSalesTaxAppliedOnVmsImport = organizationClient.OrganizationClientRoles[0].IsPaySalesTaxAppliedOnExpenseImport;
                  }
                });
              }
            });
          }
        }
      }
    }
  }

  private changePaymentRates(paymentInfos: Array<IPaymentInfo>, subdivisionId: number): boolean {
    let isChanged = false;

    if (paymentInfos && subdivisionId) {
      paymentInfos.forEach(paymentInfo => {
        if (paymentInfo?.PaymentRates) {
          paymentInfo.PaymentRates.forEach(paymentRate => {
            const defaultDeductionConfig = PhxConstants.DefaultPaymentRateDeductions.find(r => r.RateTypeId === paymentRate.RateTypeId);

            const defaultConfig = defaultDeductionConfig ? defaultDeductionConfig.defaults.find(config => config.SubdivisionId === subdivisionId) : null;

            if (defaultConfig) {

              if (paymentRate.IsApplyVacation !== defaultConfig.IsApplyVacation || paymentRate.IsApplyDeductions !== defaultConfig.IsApplyDeductions) {
                isChanged = true;
                paymentRate.IsApplyVacation = defaultConfig.IsApplyVacation;
                paymentRate.IsApplyDeductions = defaultConfig.IsApplyDeductions;
              }
            }
          });
        }
      });
    }

    return isChanged;
  }

  private setAutoCalculateOvertimeFieldUpdates(): void {
    /** NOTE: auto-calculate OT is only applicable under certain conditions - story #45343 */
    const timeInvoiceDetailsForm = this.timeMaterialInvoiceTabFormService.formGroup.controls.TabTimeMaterialInvoiceDetail as FormGroup<ITabTimeMaterialInvoiceDetail>;
    const overtimeDetailsFormGroup = this.partyPaymentInfoFormService.formGroup.controls.OverTimeDetails as FormGroup<OvertimeDetails>;

    combineLatest([
      timeInvoiceDetailsForm.controls.TimeSheetMethodologyId.valueChanges.pipe(startWith(timeInvoiceDetailsForm.controls.TimeSheetMethodologyId.value)),
      timeInvoiceDetailsForm.controls.IsTimeSheetUsesProjects.valueChanges.pipe(startWith(timeInvoiceDetailsForm.controls.IsTimeSheetUsesProjects.value)),
      overtimeDetailsFormGroup.controls.AutoCalculateOvertime.valueChanges.pipe(startWith(overtimeDetailsFormGroup.controls.AutoCalculateOvertime.value))
    ]).pipe(
      /** Due to the nature of the form updating without event emission, we have to get all values manually */
      map(() => ({
        tsMethodology: timeInvoiceDetailsForm.controls.TimeSheetMethodologyId.value,
        isUsingProjects: timeInvoiceDetailsForm.controls.IsTimeSheetUsesProjects.value,
        isAutoCalculatingOvertime: overtimeDetailsFormGroup.controls.AutoCalculateOvertime.value
      })),
      switchMap(({tsMethodology, isUsingProjects, isAutoCalculatingOvertime}) => {
        // Prevent any side effects for a readonly work order
        if(!this.workOrder?.readOnlyStorage?.IsEditable || this.workOrder?.readOnlyStorage.isTemplate){
          return of(null);
        }

        let messageForUser: string | null = null;
        const disableAutoCalculateOvertimeField = (message?: string) => {
          if (isAutoCalculatingOvertime) {
            overtimeDetailsFormGroup.controls.AutoCalculateOvertime.patchValue(false, { emitEvent: false });
            messageForUser = message;
          }
          overtimeDetailsFormGroup.controls.AutoCalculateOvertime.disable({ emitEvent: false });
          overtimeDetailsFormGroup.controls.AutoCalculateOvertimeExemptionReason.patchValue(null, { emitEvent: false });
          overtimeDetailsFormGroup.controls.AutoCalculateOvertimeExemptionReason.disable({ emitEvent: false });
        };

        if (isUsingProjects) {
          disableAutoCalculateOvertimeField('\'Auto-calculate Overtime Hours\' is not supported when Timesheets have Projects details, and has been updated to \'No\'.');
        } else if (tsMethodology === PhxConstants.TimeSheetMethodology.ThirdPartyImport) {
          disableAutoCalculateOvertimeField('\'Auto-calculate Overtime Hours\' is not supported when Timesheet Methodology is \'Third Party Imports\', and has been updated to "No".');
        } else if (tsMethodology === PhxConstants.TimeSheetMethodology.NoTimesheet) {
          disableAutoCalculateOvertimeField();
        } else if (overtimeDetailsFormGroup.controls.AutoCalculateOvertime.disabled && !isUsingProjects && (
          tsMethodology === PhxConstants.TimeSheetMethodology.OnlineApproval ||
          tsMethodology === PhxConstants.TimeSheetMethodology.OfflineApproval
        )) {
          messageForUser = '\'Auto-calculate Overtime Hours\' is now available on Parties and Rates tab, and has been updated to \'Yes\'.';
          overtimeDetailsFormGroup.controls.AutoCalculateOvertime.patchValue(true, { emitEvent: false });
          overtimeDetailsFormGroup.controls.AutoCalculateOvertime.enable({ emitEvent: false });
        } else {
          /** NOTE: if we are here then user is changing autocalculate overtime on parties and rates tab */
          messageForUser = null;
          if(overtimeDetailsFormGroup.controls.AutoCalculateOvertime.value === false) {
            overtimeDetailsFormGroup.controls.AutoCalculateOvertimeExemptionReason.patchValue(null, { emitEvent: false });
            overtimeDetailsFormGroup.controls.AutoCalculateOvertimeExemptionReason.enable({ emitEvent: false });  
          }else{
            overtimeDetailsFormGroup.controls.AutoCalculateOvertimeExemptionReason.patchValue(null, { emitEvent: false });
            overtimeDetailsFormGroup.controls.AutoCalculateOvertimeExemptionReason.disable({ emitEvent: false });  
          }
        }

        return of(messageForUser);
      }),
      filter((messageForUser) => Boolean(messageForUser)),
      takeUntil(this.isRootComponentDestroyed$)
    ).subscribe((messageForUser) => {
      void this.dialogService.notify('Auto-calculate Overtime', messageForUser, { backdrop: 'static', size: 'md' });
    });
  }
}
