import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  forwardRef,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  SimpleChanges,
  TemplateRef,
  TransferState
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import DevExpress from 'devextreme/bundles/dx.all';
import { DxTemplateHost, WatcherHelper } from 'devextreme-angular';
import { v4 as uuidv4 } from 'uuid';

import { PhxSelectBoxComponent } from '../phx-select-box/phx-select-box.component';

@Component({
  selector: 'app-phx-select-box-w-tooltip',
  templateUrl: './phx-select-box-w-tooltip.component.html',
  styleUrls: ['./phx-select-box-w-tooltip.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => PhxSelectBoxWTooltipComponent)
    }
  ]
})
export class PhxSelectBoxWTooltipComponent extends PhxSelectBoxComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  @Input() showTooltip = true; // TODO: do this on the item level?

  @ContentChild(TemplateRef) templateRef: TemplateRef<{ itemData: any }>;

  guid: string;

  buttons: Array<string |  DevExpress.common.TextEditorButton>;

  constructor(
    eRef: ElementRef,
    ngZone: NgZone,
    templateHost: DxTemplateHost,
    _watcherHelper: WatcherHelper,
    transferState: TransferState,
    @Inject(PLATFORM_ID) platformId: any,
    cdr: ChangeDetectorRef
  ) {
    super(eRef, ngZone, templateHost, _watcherHelper, transferState, platformId, cdr);
  }

  ngOnInit() {
    this.guid = uuidv4();

    this.buttons = [
      {
        location: 'after',
        name: 'tooltip',
        options: {
          icon: 'info',
          stylingMode: 'text',
          elementAttr: {
            id: `tooltip-selected-${this.guid}`
          }
        }
      },
      'clear',
      'dropDown'
    ];
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();

    this.setEditorTooltipButtonVisible();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    if (changes.showTooltip || changes.value) {
      this.setEditorTooltipButtonVisible();
    }
  }

  onValueChanged(event) {
    super.onValueChanged(event);
    this.setEditorTooltipButtonVisible();
  }

  setEditorTooltipButtonVisible() {
    if (this.selectBox?.instance) {
      const visible = this.showTooltip && this.value != null;

      const tooltipButton = this.selectBox.instance.getButton('tooltip');
      if (tooltipButton) {
        tooltipButton.option('visible', visible);
      }
    }
  }

}
