import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { IFormService, PhxConstants } from '../../../common/model';
import { FormArray, FormBuilder, FormGroup } from '../../../common/ngx-strongly-typed-forms/model';
import { IBillingPartyInfoes, IBillingRate, IBillingRatesDetails, IClientDiscount, IPartiesRateDetail, IRebateAndVMSFee, IWorkOrder } from '../../models';
import { PtFieldViewCustomValidator } from '../../ptFieldCustomValidator';
import { CustomValidators } from 'src/app/common/validators/CustomValidators';
import { PartyPaymentInfoFormService } from './party-payment-info-form.service';
import { Validators } from '@angular/forms';


@Injectable()
export class PartyBillingInfoFormService implements IFormService {

  readonly flowdownFeeProfileTypes = [PhxConstants.UserProfileType.WorkerSubVendor];

  formGroup: FormGroup<IBillingPartyInfoes>;
  private isRootComponentDestroyed$: Subject<boolean>;
  private isEligibleProfileForAutocalculateOvertime = false;

  constructor(
    private fb: FormBuilder,
    private partyPaymentInfoFormService: PartyPaymentInfoFormService
  ) { }

  get partiesRateDetailFormArray(): FormArray<IPartiesRateDetail> {
    return this.formGroup.get('PartiesRateDetails') as FormArray<IPartiesRateDetail>;
  }

  get billingRateFormArray(): FormArray<IBillingRate> {
    const formGroup = this.partiesRateDetailFormArray.at(0) as FormGroup<IPartiesRateDetail>;
    const billingRatesDetail = formGroup ? formGroup.get('BillingRatesDetail') : null;
    return billingRatesDetail ? billingRatesDetail.get('BillingRates') as FormArray<IBillingRate> : null;
  }


  createForm(workorder: IWorkOrder, isDestroyed$: Subject<boolean>) {
    this.isRootComponentDestroyed$ = isDestroyed$;
    const billingPartiesDetails: Array<IPartiesRateDetail> = this.mapWorkOrderToFormData(workorder);

    this.formGroup = this.fb.group<IBillingPartyInfoes>({
      PartiesRateDetails: this.createPartiesRateDetailFormArray(billingPartiesDetails)
    });

    return this.formGroup;
  }

  destroyForm() {
    this.formGroup = null;
  }

  setupFormListeners() {
    this.isRootComponentDestroyed$.subscribe(() => {
      this.destroyForm();
    });
  }

  formGroupToPartial(workOrder: IWorkOrder): IWorkOrder {

    const partiesRateDetailFormArray = this.partiesRateDetailFormArray;
    const firstPartiesRateDetailFormGroup = partiesRateDetailFormArray.length ? partiesRateDetailFormArray.at(0) : null;
    const rebateVmsFeeFormGroup = firstPartiesRateDetailFormGroup ? firstPartiesRateDetailFormGroup.get('RebateAndVMSFee') : null;
    const clientDiscountFormGroup = firstPartiesRateDetailFormGroup ? firstPartiesRateDetailFormGroup.get('ClientDiscount') : null;

    // copy billingInfo from forms to work order model
    if (partiesRateDetailFormArray.length) {
      partiesRateDetailFormArray.value.forEach((partiesRateDetail, index) => {

        workOrder.WorkOrderVersion.BillingInfoes[index] = {
          ...workOrder.WorkOrderVersion.BillingInfoes[index],
          CurrencyId: partiesRateDetail.CurrencyId,
          UserProfileIdClient: partiesRateDetail.UserProfileIdClient,
          Hours: partiesRateDetail.Hours,
          OrganizationIdClient: partiesRateDetail.OrganizationIdClient,
          BillingRates: partiesRateDetail.BillingRatesDetail.BillingRates
        };
      });
    }

    this.rebateVmsFeeFormGroupToPartial(workOrder, rebateVmsFeeFormGroup as FormGroup<IRebateAndVMSFee>);
    this.clientDiscountFormGroupToPartial(workOrder, clientDiscountFormGroup as FormGroup<IClientDiscount>);
    return workOrder;
  }

  updateForm(workorder: IWorkOrder): void {
    const billingPartiesDetails: Array<IPartiesRateDetail> = this.mapWorkOrderToFormData(workorder);

    this.formGroup.patchValue({
      PartiesRateDetails: billingPartiesDetails
    }, { emitEvent: false });

    this.updatePartiesRateDetailFormArray(this.partiesRateDetailFormArray, billingPartiesDetails);
  }

  addBillingPartyRate() {
    const formGroup = this.createBlankBillingRateFormGroup();
    this.billingRateFormArray.push(formGroup);
  }

  deleteBillingPartyRate(rateIndex) {
    const billingRateFormArray = this.billingRateFormArray;

    if (billingRateFormArray) {
      const billingRate = billingRateFormArray.at(rateIndex);
      const RateTypeId = billingRate ? billingRate.get('RateTypeId') : null;
      const rateTypeIdValue = RateTypeId ? RateTypeId.value : null;
      this.partyPaymentInfoFormService.deletePaymentPartyRate(rateTypeIdValue);
      billingRateFormArray.removeAt(rateIndex);
    }
  }

  updateCurrenyIdFormControl(index: number, value: number, emitEvent = false) {
    const formGroup = this.partiesRateDetailFormArray.at(index) as FormGroup<IPartiesRateDetail>;
    const formControl = formGroup.get('CurrencyId');
    formControl.patchValue(value, { emitEvent });
  }

  updateRateUnitIdFormControl(value: number, currentRateTypeId) {
    for (let i = 0; i < (this.billingRateFormArray ? this.billingRateFormArray.length : 0); i++) {
      const fgBillingRate = this.billingRateFormArray.at(i) as FormGroup<IBillingRate>;
      const fgBillingRateVal = fgBillingRate.value || {};
      if (fgBillingRateVal.RateTypeId === currentRateTypeId) {
        fgBillingRate.get('RateUnitId').patchValue(value, { emitEvent: false });
      }
    }
  }

  private mapWorkOrderToFormData(workorder: IWorkOrder): Array<IPartiesRateDetail> {
    const billingPartiesDetails: Array<IPartiesRateDetail> = [];
    const rebateAndVMSFee: IRebateAndVMSFee = {
      HasRebate: workorder.WorkOrderVersion.HasRebate,
      HasVmsFee: workorder.WorkOrderVersion.HasVmsFee,
      RebateHeaderId: workorder.WorkOrderVersion.RebateHeaderId,
      RebateTypeId: workorder.WorkOrderVersion.RebateTypeId,
      RebateRate: workorder.WorkOrderVersion.RebateRate,
      VmsFeeHeaderId: workorder.WorkOrderVersion.VmsFeeHeaderId,
      VmsFeeTypeId: workorder.WorkOrderVersion.VmsFeeTypeId,
      VmsFeeRate: workorder.WorkOrderVersion.VmsFeeRate,
      IsFlowdownFee: this.isSubVendor(workorder) ? workorder.WorkOrderVersion.IsFlowdownFee : false
    };
    const clientDiscount: IClientDiscount = {
      HasClientDiscount: workorder.WorkOrderVersion.HasClientDiscount,
      HasClientPercentDiscount: workorder.WorkOrderVersion.HasClientPercentDiscount,
      HasClientPerHourDiscount: workorder.WorkOrderVersion.HasClientPerHourDiscount,
      HasClientFlatDiscountAmount: workorder.WorkOrderVersion.HasClientFlatDiscountAmount,
      ClientPercentDiscount: workorder.WorkOrderVersion.ClientPercentDiscount,
      ClientFlatDiscountAmount: workorder.WorkOrderVersion.ClientFlatDiscountAmount,
      ClientPerHourDiscount: workorder.WorkOrderVersion.ClientPerHourDiscount,
      ClientDiscountDescription: workorder.WorkOrderVersion.ClientDiscountDescription
    };

    workorder.WorkOrderVersion.BillingInfoes.forEach(element => {
      const billingPartiesRates: IPartiesRateDetail = {
        Id: element.Id,
        Hours: element.Hours,
        CurrencyId: element.CurrencyId,
        UserProfileIdClient: element.UserProfileIdClient,
        OrganizationIdClient: element.OrganizationIdClient,
        OrganizationClientDisplayName: element.OrganizationClientDisplayName,
        BillingRatesDetail: {
          BillingRates: element.BillingRates
        },
        RebateAndVMSFee: rebateAndVMSFee,
        ClientDiscount: clientDiscount
      };
      billingPartiesDetails.push(billingPartiesRates);
    });

    return billingPartiesDetails;
  }

  private createPartiesRateDetailFormArray(partiesRateDetails: Array<IPartiesRateDetail>): FormArray<IPartiesRateDetail> {
    return this.fb.array<IPartiesRateDetail>(
      partiesRateDetails.map((partiesRateDetail: IPartiesRateDetail) => this.createPartiesRateDetailFormGroup(partiesRateDetail))
    );
  }

  private createPartiesRateDetailFormGroup(partiesRateDetail: IPartiesRateDetail): FormGroup<IPartiesRateDetail> {
    return this.fb.group<IPartiesRateDetail>({
      Id: [partiesRateDetail.Id],
      OrganizationIdClient: [partiesRateDetail.OrganizationIdClient],
      UserProfileIdClient: [
        partiesRateDetail.UserProfileIdClient,
        PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.BillingInfoes', 'UserProfileIdClient', [
          Validators.required
        ])
      ],
      Hours: [
        partiesRateDetail.Hours,
        PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.BillingInfoes', 'Hours', [
          Validators.required
        ])
      ],
      CurrencyId: [
        partiesRateDetail.CurrencyId,
        PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.BillingInfoes', 'CurrencyId', [
          Validators.required
        ])
      ],
      OrganizationClientDisplayName: [partiesRateDetail.OrganizationClientDisplayName],
      BillingRatesDetail: this.createBillingRatesDetailFormGroup(partiesRateDetail.BillingRatesDetail),
      RebateAndVMSFee: this.createRebateAndVMSFeeFormGroup(partiesRateDetail.RebateAndVMSFee),
      ClientDiscount: this.createClientDiscountFormGroup(partiesRateDetail.ClientDiscount)
    });
  }

  private createBillingRatesDetailFormGroup(billingRateDetail: IBillingRatesDetails): FormGroup<IBillingRatesDetails> {
    return this.fb.group<IBillingRatesDetails>({
      BillingRates: this.createBillingRateFormArray(billingRateDetail.BillingRates),
      selectedRateType: [billingRateDetail.BillingRates]
    });
  }

  private createBillingRateFormArray(billingRates: Array<IBillingRate>): FormArray<IBillingRate> {
    return this.fb.array<IBillingRate>(
      billingRates.map((billingRate: IBillingRate) => this.createBillingRateFormGroup(billingRate))
    );
  }

  private createBillingRateFormGroup(billingRate: IBillingRate): FormGroup<IBillingRate> {
    return this.fb.group<IBillingRate>({
      BillingInfoId: [billingRate.BillingInfoId],
      Id: [billingRate.Id],
      IsDraft: [billingRate.IsDraft],
      Rate: [
        billingRate.Rate,
        PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.BillingInfoes.BillingRate', 'Rate', [
          Validators.required
        ])
      ],
      RateTypeId: [billingRate.RateTypeId, [Validators.required]],
      RateUnitId: [
        billingRate.RateUnitId,
        PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.BillingInfoes.BillingRate', 'RateUnitId', [
          Validators.required
        ])
      ],
      SourceId: [billingRate.SourceId],
      Description: [billingRate.Description],
      Billable: [billingRate.Billable ?? true]
    });
  }

  private createBlankBillingRateFormGroup(): FormGroup<IBillingRate> {
    return this.fb.group<IBillingRate>({
      BillingInfoId: [0],
      Id: [0],
      IsDraft: [null],
      Rate: [null, [Validators.required]],
      RateTypeId: [null, [Validators.required]],
      RateUnitId: [null, [Validators.required]],
      SourceId: [null],
      Description: null,
      Billable: [true]
    });
  }

  private createRebateAndVMSFeeFormGroup(rebateAndVMSFee: IRebateAndVMSFee): FormGroup<IRebateAndVMSFee> {
    return this.fb.group<IRebateAndVMSFee>({
      HasRebate: rebateAndVMSFee.HasRebate,
      RebateHeaderId: rebateAndVMSFee.RebateHeaderId,
      RebateTypeId: [
        rebateAndVMSFee.RebateTypeId,
        !rebateAndVMSFee.RebateHeaderId && rebateAndVMSFee.HasRebate
          ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion', 'RebateTypeId', [
            Validators.required
          ])
          : null
      ],
      RebateRate: [
        rebateAndVMSFee.RebateRate,
        !rebateAndVMSFee.RebateHeaderId && rebateAndVMSFee.HasRebate
          ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion', 'RebateTypeId', [
            Validators.required
          ])
          : null
      ],
      VmsFeeHeaderId: rebateAndVMSFee.VmsFeeHeaderId,
      HasVmsFee: rebateAndVMSFee.HasVmsFee,
      VmsFeeTypeId: [
        rebateAndVMSFee.VmsFeeTypeId,
        !rebateAndVMSFee.VmsFeeHeaderId && rebateAndVMSFee.HasVmsFee
          ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion', 'RebateTypeId', [
            Validators.required
          ])
          : null
      ],
      VmsFeeRate: [
        rebateAndVMSFee.VmsFeeRate,
        !rebateAndVMSFee.VmsFeeHeaderId && rebateAndVMSFee.HasVmsFee
          ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion', 'RebateTypeId', [
            Validators.required
          ])
          : null
      ],
      IsFlowdownFee: [
        rebateAndVMSFee.IsFlowdownFee,
        !rebateAndVMSFee.VmsFeeHeaderId && rebateAndVMSFee.HasVmsFee
          ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrder', 'IsFlowdownFee', [
            Validators.required
          ])
          : null
      ]
    });
  }

  private isSubVendor(workOrder: IWorkOrder): boolean {
    const isWorkerSubVendor = workOrder ? this.flowdownFeeProfileTypes.includes(workOrder.workerProfileTypeId) : false;
    const doesSubVendorOrganizationExist = workOrder?.WorkOrderVersion?.PaymentInfoes.some(pi => pi.IsOrganizationSupplierSubVendor);
    return isWorkerSubVendor || doesSubVendorOrganizationExist;
  }

  private rebateVmsFeeFormGroupToPartial(workOrder: IWorkOrder, rebateVmsFeeFormGroup: FormGroup<IRebateAndVMSFee>): IWorkOrder {

    const vmsFeeDetails: IRebateAndVMSFee = rebateVmsFeeFormGroup.value;
    workOrder.WorkOrderVersion.HasRebate = vmsFeeDetails.HasRebate;
    workOrder.WorkOrderVersion.HasVmsFee = vmsFeeDetails.HasVmsFee;
    workOrder.WorkOrderVersion.RebateHeaderId = vmsFeeDetails.RebateHeaderId;
    workOrder.WorkOrderVersion.RebateTypeId = vmsFeeDetails.RebateTypeId;
    workOrder.WorkOrderVersion.VmsFeeHeaderId = vmsFeeDetails.VmsFeeHeaderId;
    workOrder.WorkOrderVersion.VmsFeeRate = vmsFeeDetails.VmsFeeRate;
    workOrder.WorkOrderVersion.VmsFeeTypeId = vmsFeeDetails.VmsFeeTypeId;
    workOrder.WorkOrderVersion.RebateRate = vmsFeeDetails.RebateRate;
    workOrder.WorkOrderVersion.IsFlowdownFee = this.isSubVendor(workOrder) ? vmsFeeDetails.IsFlowdownFee : false;
    return workOrder;
  }

  private createClientDiscountFormGroup(clientDiscount: IClientDiscount): FormGroup<IClientDiscount> {
    const formGroup = this.fb.group<IClientDiscount>({
      HasClientDiscount: clientDiscount.HasClientDiscount,
      HasClientPercentDiscount: [clientDiscount.HasClientPercentDiscount],
      HasClientFlatDiscountAmount: clientDiscount.HasClientFlatDiscountAmount,
      HasClientPerHourDiscount: clientDiscount.HasClientPerHourDiscount,
      ClientPercentDiscount: clientDiscount.ClientPercentDiscount,
      ClientFlatDiscountAmount: clientDiscount.ClientFlatDiscountAmount,
      ClientPerHourDiscount: clientDiscount.ClientPerHourDiscount,
      ClientDiscountDescription: [clientDiscount.ClientDiscountDescription],
      DiscountOptions: [clientDiscount.DiscountOptions]
    });

    this.setClientDiscountValidation(
      formGroup,
      clientDiscount.HasClientDiscount
    );

    return formGroup;
  }

  setClientDiscountValidation(formGroup: FormGroup<IClientDiscount>, hasClientDiscount: boolean) {

    formGroup.get('ClientDiscountDescription').setValidators(
      hasClientDiscount ? Validators.required : null
    );

    const discountOptionFormGroup = formGroup.get('DiscountOptions');

    if (hasClientDiscount) {
      discountOptionFormGroup
        .setValidators([
          CustomValidators.requiredAtLeastOneCheckbox([
            'HasClientPercentDiscount',
            'HasClientPerHourDiscount',
            'HasClientFlatDiscountAmount'
          ], 'At least one discount option should be checked'),
          CustomValidators.requiredCorresponsindNumericInput(['HasClientPercentDiscount', 'ClientPercentDiscount'], 'Percent discount value is required'),
          CustomValidators.requiredCorresponsindNumericInput(['HasClientPerHourDiscount', 'ClientPerHourDiscount'], 'Per hour discount value is required'),
          CustomValidators.requiredCorresponsindNumericInput(['HasClientFlatDiscountAmount', 'ClientFlatDiscountAmount'], 'Flat amount discount value is required')
        ]);
    } else {
      discountOptionFormGroup.setValidators(null);
    }

    discountOptionFormGroup.updateValueAndValidity();
  }

  private clientDiscountFormGroupToPartial(workOrder: IWorkOrder, clientDiscountFormGroup: FormGroup<IClientDiscount>): IWorkOrder {
    const clientDiscountDetails: IClientDiscount = clientDiscountFormGroup.value;
    workOrder.WorkOrderVersion.HasClientDiscount = clientDiscountDetails.HasClientDiscount;
    workOrder.WorkOrderVersion.HasClientPercentDiscount = clientDiscountDetails.HasClientPercentDiscount;
    workOrder.WorkOrderVersion.HasClientFlatDiscountAmount = clientDiscountDetails.HasClientFlatDiscountAmount;
    workOrder.WorkOrderVersion.HasClientPerHourDiscount = clientDiscountDetails.HasClientPerHourDiscount;
    workOrder.WorkOrderVersion.ClientPercentDiscount = clientDiscountDetails.ClientPercentDiscount;
    workOrder.WorkOrderVersion.ClientFlatDiscountAmount = clientDiscountDetails.ClientFlatDiscountAmount;
    workOrder.WorkOrderVersion.ClientPerHourDiscount = clientDiscountDetails.ClientPerHourDiscount;
    workOrder.WorkOrderVersion.ClientDiscountDescription = clientDiscountDetails.ClientDiscountDescription;
    return workOrder;
  }

  private updatePartiesRateDetailFormArray(
    formArray: FormArray<IPartiesRateDetail>,
    items: Partial<IPartiesRateDetail>[]
  ) {
    if (formArray.length && items.length) {
      items.forEach((item, index) => {
        const formGroup = formArray.at(index) as FormGroup<IPartiesRateDetail>;
        if (formGroup) {
          this.updatePartiesRateDetailFormGroup(formGroup, item);
        } else {
          formArray.push(this.createPartiesRateDetailFormGroup(item));
        }
      });
      if (formArray.length > items.length) {
        this.clearArray(formArray, items.length);
      }
    } else if (items.length) {
      const array = this.createPartiesRateDetailFormArray(items);
      array.controls.forEach(group => formArray.push(group));
    } else {
      this.clearArray(formArray);
    }
  }

  private updatePartiesRateDetailFormGroup(
    formGroup: FormGroup<IPartiesRateDetail>,
    partiesRateDetail: Partial<IPartiesRateDetail> = {}
  ) {

    formGroup.get('UserProfileIdClient').setValidators(
      PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.BillingInfoes', 'UserProfileIdClient', [
        Validators.required
      ])
    );

    formGroup.get('Hours').setValidators(
      PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.BillingInfoes', 'Hours', [
        Validators.required
      ])
    );

    formGroup.get('CurrencyId').setValidators(
      PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.BillingInfoes', 'CurrencyId', [
        Validators.required
      ])
    );

    formGroup.patchValue({
      Id: partiesRateDetail.Id,
      OrganizationIdClient: partiesRateDetail.OrganizationIdClient,
      UserProfileIdClient: partiesRateDetail.UserProfileIdClient,
      Hours: partiesRateDetail.Hours,
      CurrencyId: partiesRateDetail.CurrencyId,
      OrganizationClientDisplayName: partiesRateDetail.OrganizationClientDisplayName,
    }, { emitEvent: false });

    const billingRatesDetailFormGroup = formGroup.get('BillingRatesDetail') as FormGroup<IBillingRatesDetails>;
    this.updateBillingRatesDetailFormGroup(billingRatesDetailFormGroup, partiesRateDetail.BillingRatesDetail.BillingRates);

    const rebateAndVMSFeeFormGroup = formGroup.get('RebateAndVMSFee') as FormGroup<IRebateAndVMSFee>;
    this.updateRebateAndVMSFeeFormGroup(rebateAndVMSFeeFormGroup, partiesRateDetail.RebateAndVMSFee);

    const clientDiscountFormGroup = formGroup.get('ClientDiscount') as FormGroup<IClientDiscount>;
    this.updateClientDiscountFormGroup(clientDiscountFormGroup, partiesRateDetail.ClientDiscount);

  }

  private updateBillingRatesDetailFormGroup(
    formGroup: FormGroup<IBillingRatesDetails>,
    billingRates: Array<Partial<IBillingRate>>
  ) {
    formGroup.patchValue({
      selectedRateType: billingRates
    }, { emitEvent: false });

    const billingRatesFormArray = formGroup.get('BillingRates') as FormArray<IBillingRate>;
    this.updateBillingRateFormArray(billingRatesFormArray, billingRates);
  }

  private updateBillingRateFormArray(formArray: FormArray<IBillingRate>, billingRates: Array<Partial<IBillingRate>>) {
    if (formArray.length && billingRates.length) {
      billingRates.forEach((item, index) => {
        const formGroup = formArray.at(index) as FormGroup<IPartiesRateDetail>;
        if (formGroup) {
          this.updateBillingRateFormGroup(formGroup, item);
        } else {
          formArray.push(this.createBillingRateFormGroup(item));
        }
      });
      if (formArray.length > billingRates.length) {
        this.clearArray(formArray, billingRates.length);
      }
    } else if (billingRates.length) {
      const array = this.createBillingRateFormArray(billingRates);
      array.controls.forEach(group => formArray.push(group));
    } else {
      this.clearArray(formArray);
    }
  }

  private updateBillingRateFormGroup(formGroup: FormGroup<IBillingRate>, billingRate: Partial<IBillingRate>) {

    formGroup.get('Rate').setValidators(
      PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.BillingInfoes.BillingRate', 'Rate', [
        Validators.required
      ])
    );

    formGroup.get('RateTypeId').setValidators(
      Validators.required
    );

    formGroup.get('RateUnitId').setValidators(
      PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion.BillingInfoes.BillingRate', 'RateUnitId', [
        Validators.required
      ])
    );

    formGroup.patchValue({
      BillingInfoId: billingRate.BillingInfoId,
      Id: billingRate.Id,
      IsDraft: billingRate.IsDraft,
      Rate: billingRate.Rate,
      RateTypeId: billingRate.RateTypeId,
      RateUnitId: billingRate.RateUnitId,
      SourceId: billingRate.SourceId,
      Description: billingRate.Description,
      Billable: billingRate.Billable
    }, { emitEvent: false });
  }

  private updateRebateAndVMSFeeFormGroup(formGroup: FormGroup<IRebateAndVMSFee>, rebateAndVMSFee: IRebateAndVMSFee) {

    formGroup.get('RebateTypeId').setValidators(
      !rebateAndVMSFee.RebateHeaderId && rebateAndVMSFee.HasRebate
        ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion', 'RebateTypeId', [
          Validators.required
        ])
        : null
    );

    formGroup.get('RebateRate').setValidators(
      !rebateAndVMSFee.RebateHeaderId && rebateAndVMSFee.HasRebate
        ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion', 'RebateTypeId', [
          Validators.required
        ])
        : null
    );

    formGroup.get('VmsFeeTypeId').setValidators(
      !rebateAndVMSFee.VmsFeeHeaderId && rebateAndVMSFee.HasVmsFee
        ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion', 'RebateTypeId', [
          Validators.required
        ])
        : null
    );

    formGroup.get('VmsFeeRate').setValidators(
      !rebateAndVMSFee.VmsFeeHeaderId && rebateAndVMSFee.HasVmsFee
        ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrderVersion', 'RebateTypeId', [
          Validators.required
        ])
        : null
    );

    formGroup.get('IsFlowdownFee').setValidators(
      !rebateAndVMSFee.VmsFeeHeaderId && rebateAndVMSFee.HasVmsFee
        ? PtFieldViewCustomValidator.checkPtFieldViewCustomValidator('WorkOrder', 'IsFlowdownFee', [
          Validators.required
        ])
        : null
    );

    formGroup.patchValue({
      HasRebate: rebateAndVMSFee.HasRebate,
      RebateHeaderId: rebateAndVMSFee.RebateHeaderId,
      RebateTypeId: rebateAndVMSFee.RebateTypeId,
      RebateRate: rebateAndVMSFee.RebateRate,
      VmsFeeHeaderId: rebateAndVMSFee.VmsFeeHeaderId,
      HasVmsFee: rebateAndVMSFee.HasVmsFee,
      VmsFeeTypeId: rebateAndVMSFee.VmsFeeTypeId,
      VmsFeeRate: rebateAndVMSFee.VmsFeeRate,
      IsFlowdownFee: rebateAndVMSFee.IsFlowdownFee
    }, { emitEvent: false });
  }

  private updateClientDiscountFormGroup(formGroup: FormGroup<IClientDiscount>, clientDiscount: IClientDiscount) {

    this.setClientDiscountValidation(
      formGroup,
      clientDiscount.HasClientDiscount
    );

    formGroup.patchValue({
      HasClientDiscount: clientDiscount.HasClientDiscount,
      HasClientPercentDiscount: clientDiscount.HasClientPercentDiscount,
      HasClientFlatDiscountAmount: clientDiscount.HasClientFlatDiscountAmount,
      HasClientPerHourDiscount: clientDiscount.HasClientPerHourDiscount,
      ClientPercentDiscount: clientDiscount.ClientPercentDiscount,
      ClientFlatDiscountAmount: clientDiscount.ClientFlatDiscountAmount,
      ClientPerHourDiscount: clientDiscount.ClientPerHourDiscount,
      ClientDiscountDescription: clientDiscount.ClientDiscountDescription,
      DiscountOptions: clientDiscount.DiscountOptions
    }, { emitEvent: false });

  }

  private clearArray(formArray: FormArray<IPartiesRateDetail>, count = 0) {
    while (formArray.length !== count && count < formArray.length) {
      formArray.removeAt(count);
    }
  }

}
